import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import PostCard from "./Card";

const CarouselWithCards = ({ posts, slug, loading }) => {

  const [currentSlide, setCurrentSlide] = useState(0);

  // Use media query to detect screen size (mobile breakpoint is 600px)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const slidesToShow = isMobile ? 1 : 3; // Show 1 slide on mobile, 3 slides on larger screens
  const mobileSlideSet = 1;

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === posts.length - slidesToShow ? 0 : prevSlide + 1
    );
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? posts.length - slidesToShow : prevSlide - 1
    );
  };

  // Handle dot click
  const handleDotClick = (index) => {
    setCurrentSlide(index * mobileSlideSet);
  };

  const totalSlides = Math.ceil(posts.length / mobileSlideSet);



  return (
    <Box sx={{ position: "relative", overflow: "hidden", maxWidth: "100%" }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          transform: `translateX(-${currentSlide * (100 / slidesToShow)}%)`,
          transition: (currentSlide !== 0 && currentSlide !== posts.length - slidesToShow)
            ? "transform 0.5s ease"
            : "transform 0s ease",
        }}
      >
        {loading ? (
          // Show three placeholders while loading
          [...Array(3)].map((_, index) => (
            <Grid
              item
              key={`placeholder-${index}`}
              sx={{
                minWidth: `${100 / slidesToShow}%`,
                boxSizing: "border-box",
                padding: "16px",
              }}
            >
              <PostCard slug={slug} post={_} loading={loading} />
            </Grid>
          ))
        ) : posts.length > 0 ? (
          // Render first 3 posts if available
          posts.map((post, index) => (
            <Grid
              item
              key={`${post.id}-carousel-item-post-blog`}
              sx={{
                minWidth: `${100 / slidesToShow}%`,
                boxSizing: "border-box",
                padding: "16px",
              }}
            >
              <PostCard slug={slug} post={post} loading={loading} />
            </Grid>
          ))
        ) : (
          // Fallback message if no posts
          <Grid item xs={12}>
            <Typography></Typography>
          </Grid>
        )}
        {/* {posts.map((post, index) => (
          <Grid
            item
            key={`${post.id}-carousel-item-post-blog`}
            sx={{
              minWidth: `${100 / slidesToShow}%`,
              boxSizing: "border-box",
              padding: "16px",
            }}
          >
            <PostCard slug={slug} post={post} />
          </Grid>
        ))} */}
      </Grid>

      {
        !isMobile && (
          <IconButton
            onClick={handlePrev}
            sx={{
              position: "absolute",
              top: "50%",
              left: "0",
              transform: "translateY(-50%)",
              backgroundColor: "white",
              transition: "background-color 0.3s ease",
              "&:hover": {
                background: "#FF6000",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              },
            }}
          >
            <ArrowBackIosIcon sx={{ color: "black" }} />
          </IconButton>

        )
      }

      {/* Right Navigation Arrow */}
      {
        !isMobile && (
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              top: "50%",
              right: "0",
              transform: "translateY(-50%)",
              backgroundColor: "white",
              transition: "background-color 0.3s ease",
              "&:hover": {
                background: "#FF6000",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              },
              // boxShadow: "0 0 10px rgba(0,0,0,0.2)",

            }}
          >
            <ArrowForwardIosIcon sx={{ color: "black" }} />
          </IconButton>
        )
      }

      {/* Dot Navigation for Mobile */}
      {
        isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            {Array.from({ length: totalSlides }).map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor:
                    currentSlide / mobileSlideSet === index ? "#FF6000" : "#ccc",
                  margin: "0 4px",
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
        )
      }
    </Box >
  );
};

export default CarouselWithCards;
