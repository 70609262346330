import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1006 from "../../assets/images/Frame1006.svg";
import Frame1007 from "../../assets/images/Frame1007.svg";
import Frame1008 from "../../assets/images/Frame1008.svg";
import Rounddesign from "../../assets/images/rounddesign.svg";
import GradientHeader from "../../components/product/GradientHeader";
import InsightGlobal from "../../components/product/InsightGlobal";
import RoundCard from "../../components/services-consulting/RoundCard";

const headtitle = "Consulting";
const headsubTitle = "コンサルティング";
const headsubdec = " サービス > コンサルティング";



const dataForCard = [
  {
    title: "手続きの簡略化による時間短縮",
    description:
      "複雑化していた処理を、データの収集、抽出方法の見直しや新たなツールの導入等により、効率的な業務遂行ができるよう手助けいたします。",
    imgSrc: Frame1006,
  },

  {
    title: "財務から在庫管理まで一元化",
    description:
      "財務データはもちろん、人事、労務、在庫など、社内に散在するデータを一元管理します。",
    imgSrc: Frame1007,
  },
  {
    title: "タイムリーなデータ分析",
    description:
      "国内外問わずKPIを常にモニタリングし、スムーズな情報共有と意思決定を実現します。",
    imgSrc: Frame1008,
    roundDesignSrc: Rounddesign,
  },
];

const cardTitle = "Service";
const cardSubTitle = "サービス内容";



const dataForRoundCard = [
  {
    title: "グローバルIT技術",

  },

  {
    title: "高度ITエンジニア",

  },
  {
    title: "イノベーション支援",

  },
];
const ServicesConsulting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>コンサルティング | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />

      <InsightGlobal
        data={dataForCard}
        title={cardTitle}
        subTitle={cardSubTitle}
        clr={true}
      />

      <RoundCard
        data={dataForRoundCard}
      />


    </>
  );
};

export default ServicesConsulting;
