import {
  Box,
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import Rounddesign from "../../../assets/images/rounddesign.svg";
import { usePostStore } from "../../../store/postStore";
import { getFeaturedMedia } from "../../../utils/postUtils";
import "./index.css";



const MemberswithGlobalSection = () => {
  const { members, getPosts } = usePostStore();

  useEffect(() => {
    getPosts('members')
  }, [getPosts])
  return (
    <Stack py={4} sx={{ position: "relative" }}>
      <Container>
        <Stack py={5}>
        <Typography
            variant="h6"
            gutterBottom
            sx={{
              background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
              fontSize: {
                xs: "14px",
                sm: "24px",
              },
              lineHeight: "33px",
              marginBottom: 0,
            }}
          >
            Members with Global Business Expertise

          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: "18px",
                sm: "50px",
              },
              color: "#000000",
              lineHeight: {xs:'20px',md:"70px"},

            }}
          >
            グローバルビジネス経験豊富なメンバー

          </Typography>
          <Typography
            // mt={6}
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 400,
              lineHeight: "36px",
              fontSize: {
                xs: "14px",
                sm: "20px",
              },
              color: "#000000",
              mt: {
                xs: 0,
                sm: 3,
              },
            }}
          >
            グローバルなビジネス経験を積んできたメンバーが


          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 400,
              lineHeight: "36px",
              fontSize: "20px",
              color: "#000000",
            }}
          >
            プロジェクトの成功をお手伝いします
          </Typography>

          <Grid container spacing={4} py={6}>
            {members.map((item, index) => (
              <Grid item xs={12} key={index} sx={{ marginBottom: "40px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        className="bold-content"
                       dangerouslySetInnerHTML={{ __html: item?.content?.rendered }} />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: { xs: "200px", md: "337px" },
                      }}
                    >
                      <img
                        src={getFeaturedMedia(item)}
                        alt={'member'}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          zIndex: 1,
                          borderRadius: 15
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Typography
            variant="h6"
            gutterBottom
            sx={{
              background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
              fontSize: {
                xs: "14px",
                sm: "24px",
              },
              lineHeight: "33px",
              marginBottom: 0,
            }}
          >
            Founding Philosophy
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: "18px",
                sm: "50px",
              },
              color: "#000000",
              lineHeight: {xs:'20px',md:"70px"},

            }}
          >
            創業の想い
          </Typography>
          <Typography
            // mt={6}
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 400,
              lineHeight: "36px",
              fontSize: {
                xs: "14px",
                sm: "20px",
              },
              color: "#000000",
              mt: {
                xs: 0,
                sm: 3,
              },
            }}
          >
            私たちは、海外進出をチャンスとみなして、日本の会社が世界に進出しやすくなるように手助けしています。日本の未来に情熱を持ち、現実的な視点で仕事に取り組んでいます。そして、日本企業が世界で成功する手助けをすることに全力で貢献してまいります。
          </Typography>
         
        </Stack>
      </Container>
      <Box
        component="img"
        src={Rounddesign}
        alt="Round Design"
        sx={{
          position: "absolute",
          top: -230,
          left: -230,
          zIndex: -1,
          width: { xs: "300px", sm: "392px", md: "492px" },
          height: { xs: "300px", sm: "392px", md: "492px" },
        }}
      />
    </Stack>
  );
};

export default MemberswithGlobalSection;
