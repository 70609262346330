import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1006 from "../../assets/images/Frame1006.svg";
import Frame1007 from "../../assets/images/Frame1007.svg";
import Frame1008 from "../../assets/images/Frame1008.svg";
import Rounddesign from "../../assets/images/rounddesign.svg";
import FeatureOurStrengthSection from "../../components/features/FeatureStrength";
import MemberswithGlobalSection from "../../components/features/MemberswithGlobal";
import NumerousProjectsSection from "../../components/features/NumerousProjects";
import GradientHeader from "../../components/product/GradientHeader";

const dataForFeature = [
  {
    title: "時間管理アプリ",
    description:
      "管理部門による従業員の業務管理を効率化とタイムリーで正確な業務実態管理により、各プロジェクトの損益把握を可能にする、従業員の時間・スケジュール管理のためのアプリを開発しました。",
    imgSrc: Frame1006,
  },

  {
    title: "３D展示会",
    description:
      "５０社超えの会社ブースが展示され、３０００人以上が来場した、３D展示会を開催。ブースにて、会社説明動画、資料ダウンロード、チャットでの問い合わせ、ミーティング設定依頼が可能。",
    imgSrc: Frame1007,
  },
  {
    title: "書籍口コミ・レビュー・動画サイト構築",
    description:
      "１０万本以上の書籍データを管理し、書籍に関するレビューや口コミのプラットフォームを持つ、口コミサイトの構築。また、SNS・動画サイトとの連携も可能とし、サイト訪問への同線も工夫。",
    imgSrc: Frame1008,
    roundDesignSrc: Rounddesign,
  },
];

const title = "Our Accomplishments";
const subTitle = "実績の一覧を見る";
const navi = '/achievements'

const headtitle = "Our strengths";
const headsubTitle = "特長";
const headsubdec = "特長";

const navi1 = '/会社概要'

const FeaturesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>特長 | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <FeatureOurStrengthSection />
      <NumerousProjectsSection
        data={dataForFeature}
        title={title}
        subTitle={subTitle}
        navi={navi}
      />
      <MemberswithGlobalSection
        navi={navi1}
      />
    </>
  );
};

export default FeaturesPage;
