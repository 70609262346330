import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Frame1009 from "../../../assets/images/Frame1009.svg";
import Rounddesign from "../../../assets/images/rounddesign.svg";
import CustomButton from "../../../common/button/CustomButton";

const Platform = () => {
  return (
    <Stack
      backgroundColor={"#FFFFFF"}
      sx={{ position: "relative", overflow: "hidden" }} // Hide overflow
    >
      <Container>
        <Stack py={5}>
          <Grid container spacing={4} py={6}>
            <Grid item xs={12} md={6}>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    background:
                      "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: { xs: "16px", md: "24px" },
                    fontWeight: 700,
                    lineHeight: "28px",
                  }}
                >
                  Platform
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "30px", md: "50px" }, // Responsive font size
                    fontWeight: 700,
                    lineHeight: { xs: "40px", md: "70px" }, // Responsive line height
                  }}
                >
                  プラットフォーム
                </Typography>
                <Typography
                  py={2}
                  mb={4}
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", md: "20px" }, // Responsive font size
                    fontWeight: 400,
                    lineHeight: "36px",
                  }}
                >
                  Insight
                  Globalは、経営陣及び運用メンバー向けのワンストップソリューションであり、データに基づいた、迅速な意思決定を行うのに必要な、リアルタイムでの分析・計画ができるプラットフォームを提供します。
                </Typography>

                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                  onClick={() =>
                    (window.location.href = "https://insightglobal.jp/aboutus")
                  }
                >
                  <CustomButton text="詳細を見る" />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mt: 4, mb: 4 }}>
              <Stack>
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "526px",
                    zIndex: 1,
                  }} // Responsive image
                  src={Frame1009}
                  alt="Frame 1002"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>

      <Box
        component="img"
        src={Rounddesign}
        alt="Circle Arrow"
        sx={{
          position: "absolute",
          top: "-17%",
          left: "-8%",
          width: { xs: "200px", md: "279px" }, // Responsive width
          height: { xs: "200px", md: "279px" }, // Responsive height
          zIndex: 0,
        }}
      />
    </Stack>
  );
};

export default Platform;
