import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1014 from "../../assets/images/Frame1014.svg";
import Micro1 from "../../assets/images/micro1.svg";
import Micro2 from "../../assets/images/micro2.svg";
import Micro3 from "../../assets/images/micro3.svg";
import Micro4 from "../../assets/images/micro4.svg";
import Microsoft from "../../assets/images/microsoftpartner.svg";
import Partner from "../../components/Partner";
import GradientHeader from "../../components/product/GradientHeader";
import AccordionScreen from "../../components/system-development/AccordionScreen";
import SpecializedDevelopment from "../../components/system-development/SpecializedDevelopment";
import StartOfDevelopment from "../../components/system-development/StartOfDevelopment";
import SystemStrengthSection from "../../components/system-development/SystemStrength";

const headtitle = "Microsoft Solution";
const headsubTitle = "Microsoftソリューション";
const headsubdec = "サービス > Microsoftソリューション";

const dataForService = [
  {
    title: "Azureのデータ分析とAI機能",
    description:
      "ビジネスデータの収集、分析、予測分析、機械学習モデルの構築が可能。",
    imgSrc: Micro1,
  },
  {
    title: "ビッグデータ処理とデータウェアハウスの構築",
    description:
      "Azureデータ分析サービスは、ビッグデータの処理、データウェアハウスの構築、リアルタイムデータ分析などを提供します。",
    imgSrc: Micro2,
  },
  {
    title: "Power Appsによるノーコード／ローコードのアプリケーション開発",
    description:
      "プログラミングの知識がなくてもアプリケーションを開発できます。",
    imgSrc: Micro3,
  },
  {
    title: "Power BIによるビジネスインテリジェンスの可視化と分析",
    description:
      "ビジネスデータをリアルタイムで可視化し、洞察を得ることができます。",
    imgSrc: Micro4,
  },
];

const serviceTitle = "Features of Microsoft solutions";
const ServicesubTitle = "Microsoftソリューションの特徴";

const dataForSystemOdoo = [
  {
    title: "ビジネスデータの効率的な分析と活用",
    description: "ビジネスデータの効率的な分析と活用",
    imgSrc: Frame1014,
  },

  {
    title: "ビジネスプロセスの自動化と効率化",
    description: "ルーチン業務の自動化により、効率を向上させます。",
    imgSrc: Frame1014,
  },
  {
    title: "顧客エンゲージメントの向上",

    description:
      "顧客データを分析し、パーソナライズされたサービスを提供します。",
    imgSrc: Frame1014,
  },
  {
    title: "ビジネス成果の最大化",

    description: "効率化と顧客満足度向上により、ビジネスの成果を最大化します。",
    imgSrc: Frame1014,
  },
];

const systemTitleOdoo = "What Microsoft solutions can solve";
const systemsubTitleOdoo = "Microsoftソリューションで解決できること";

const dataForIntro = [
  {
    no: "01",
    title: "要件収集と分析",
    description:
      "ビジネスニーズを特定し、ソリューションの要件を詳細に定義し、適切なソリューションを選択します。",
  },
  {
    no: "02",
    title: "プロジェクト計画と設計",
    description:
      "導入プロジェクトの計画を立て、ソリューションの設計とカスタマイズを行います。 ",
  },

  {
    no: "03",
    title: "システム構築と設定",
    description:
      "ソリューションのインストールと構成を行い、必要に応じてカスタマイズや設定を行います。 ",
  },

  {
    no: "04",
    title: "ユーザートレーニングと展開",
    description:
      "従業員へのトレーニングを実施し、システムの展開を行います。ユーザーのフィードバックを収集し、必要な調整を行います。 ",
  },

  {
    no: "05",
    title: "サポートとメンテナンス",
    description:
      "導入後のサポートと定期的なメンテナンスを提供し、システムの安定性と効率性を確保します。",
  },
];

const systemTitleFlow = "Introduction flow";
const systemsubTitleFlow = "導入の流れ";

const partnerTitle = "Microsoftソリューションとは";
const partnerSubTitle =
  "Microsoftソリューションは、Azure data analytics & AI、Azure data analytics services、Power Apps、Power BI、Power Automationなどを含む、革新的なビジネスソリューションです。";

const partnerImg = Microsoft;

const dataForAccordion = [
  {
    title: "どのようにしてデータ分析を始めることができますか？",
    answer:'データ分析の第一歩は、ビジネスの目標や課題を明確にすることです。次に、分析に必要なデータを収集し、整理します。Power BIなどのツールを使用してデータを視覚化し、インサイトを得ることで、データに基づいた意思決定が可能になります。また、専門的なサポートを活用すれば、分析プロセスをスムーズに進めることができます。'
  },
  {
    title: "Power Appsでのアプリケーション開発は難しいですか？",
    answer:'Power Appsはローコードプラットフォームで、専門的なプログラミングスキルがなくてもアプリを作成できます。テンプレートやドラッグ＆ドロップ機能を活用して、業務に合わせたアプリを素早く開発可能です。複雑な要件がある場合でも、拡張機能や専門知識を使って対応できます。そのため、柔軟で直感的な開発環境が提供されています。'

  },
  {
    title: "ワークフローの自動化にどのくらいの時間がかかりますか？",
    answer:'ワークフローの自動化にかかる時間は、そのプロセスの複雑さによります。シンプルな自動化の場合、数日で実装可能ですが、複雑なプロセスやシステム統合が含まれる場合は、数週間から1か月程度かかることがあります。具体的な時間はプロジェクト開始時に詳細な要件を確認し、見積もりを行います。'

  },
  {
    title: "ソリューションの導入後のトレーニングは提供されますか？",
    answer:'導入後には、ソリューションを最大限に活用するためのトレーニングを提供しています。ユーザーの習熟度に応じて、オンラインまたは対面でのトレーニングを柔軟に実施可能です。加えて、導入後もサポートが継続され、トラブルシューティングや追加の学習が必要な場合にも対応します。'

  },
];


const titleAccordion = "よくあるご質問"
const ServiceMicrosoftSolution = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Microsoftソリューション | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <Partner
        img={partnerImg}
        title={partnerTitle}
        subTitle={partnerSubTitle}
      />
      <SpecializedDevelopment
        data={dataForSystemOdoo}
        title={systemTitleOdoo}
        subTitle={systemsubTitleOdoo}
      />

      <SystemStrengthSection
        data={dataForService}
        title={serviceTitle}
        subTitle={ServicesubTitle}
      />
      <StartOfDevelopment
        data={dataForIntro}
        title={systemTitleFlow}
        subTitle={systemsubTitleFlow}
      />
      <AccordionScreen data={dataForAccordion}
        title={titleAccordion} />
    </>
  );
};

export default ServiceMicrosoftSolution;
