import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const Partner = ({title, subTitle, img}) => {
  return (
    <Stack backgroundColor={"#FFFFFF"}>
      <Container>
        <Stack py={5}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={4}>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    maxWidth: "214px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  src={img}
                  alt="Golden"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8}>
             <Stack sx={{display:'flex', justifyContent:'center', alignItems:'flex-start', minHeight:'215px'}}>
             <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "38px" },
                  lineHeight: { xs: "32px", sm: "53px" },
                  color: "#000000",
                }}
              >
               {title}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: { xs: "16px", sm: "20px" },
                  fontWeight: 400,
                  lineHeight: { xs: "24px", sm: "36px" },
                  color: "#000000",
                }}
              >
               {subTitle}
             
              </Typography>
             </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Partner;
