import {
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import Rounddesign from "../../../assets/images/rounddesign.svg";
import { usePostStore } from "../../../store/postStore";
import { getFeaturedMedia } from "../../../utils/postUtils";
import "./index.css";

const FeatureOurStrengthSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if the screen is mobile (below "md")
  const { strengths, getPosts } = usePostStore();

  useEffect(() => {
    getPosts("strengths");
  }, [getPosts]);


  return (
    <Container>
      <Stack py={5}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
            fontSize: {
              xs: "14px",
              sm: "24px",
            },
            lineHeight: "33px",
            marginBottom: 0,
          }}
        >
          What we do best
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: {
              xs: "18px",
              sm: "50px",
            },
            color: "#000000",
            lineHeight: { xs: "20px", md: "70px" },
          }}
        >
          私たちの３つの強み
        </Typography>
        <Typography
          // mt={6}
          variant="body1"
          gutterBottom
          sx={{
            fontWeight: 400,
            lineHeight: "36px",
            fontSize: {
              xs: "14px",
              sm: "20px",
            },
            color: "#000000",
            mt: {
              xs: 0,
              sm: 3,
            },
          }}
        >
          グローバルジャパンネットワークはグローバルビジネスとテク
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontWeight: 400,
            lineHeight: "36px",
            fontSize: "20px",
            color: "#000000",
          }}
        >
          ノロジーに精通した専門家が、日本企業の成功をサポートします。
        </Typography>

        <Grid container spacing={4} py={6}>
          {strengths?.map((item, index) => (
            <React.Fragment key={index}>
              {isMobile || index % 2 === 1 ? (
                <>
                  <Grid item xs={12} md={6} sx={{ mt: 4, mb: 4 }}>
                    <Stack
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: { xs: "250px", md: "337px" },
                      }}
                    >
                      <img
                        src={getFeaturedMedia(item)}
                        alt="Frame 1003"
                        style={{
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                      />
                      <img
                        style={{
                          position: "absolute",
                          borderRadius: "12px",
                          top: "-100px",
                          left: "-70px",
                          width: { xs: "150px", md: "233px" },
                          height: { xs: "150px", md: "233px" },
                        }}
                        src={Rounddesign}
                        alt="Round Design"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        className="bold-content"
                        dangerouslySetInnerHTML={{
                          __html: item?.content?.rendered,
                        }}
                      />

                      {/* <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#FF6000",
                          fontSize: { xs: "18px", md: "20px" },
                          fontWeight: 700,
                          lineHeight: { xs: "24px", md: "28px" },
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: { xs: "16px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "28px", md: "32px" },
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {item.description}
                      </Typography> */}

                      {/* <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: {
                            xs: "center",
                            md: "flex-start",
                          },
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                            lineHeight: "22px",
                          }}
                        >
                          詳細を見る
                        </Typography>
                        <img
                          src={CircleArrow}
                          alt="Circle Arrow"
                          style={{
                            paddingLeft: "1em",
                            width: "40px",
                            height: "40px",
                          }}
                        />
                      </Stack> */}
                    </Stack>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#FF6000",
                          fontSize: { xs: "18px", md: "20px" },
                          fontWeight: 700,
                          lineHeight: { xs: "24px", md: "28px" },
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: { xs: "16px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "28px", md: "32px" },
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {item.description}
                      </Typography> */}
                      <div
                        className="bold-content"
                        dangerouslySetInnerHTML={{
                          __html: item?.content?.rendered,
                        }}
                      />

                      {/* <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                            lineHeight: "22px",
                          }}
                        >
                          詳細を見る
                        </Typography>
                        <img
                          src={CircleArrow}
                          alt="Circle Arrow"
                          style={{
                            paddingLeft: "1em",
                            width: "40px",
                            height: "40px",
                          }}
                        />
                      </Stack> */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 4, mb: 4 }}>
                    <Stack
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: { xs: "250px", md: "337px" },
                      }}
                    >
                      <img
                        src={getFeaturedMedia(item)}
                        alt="Frame 1003"
                        style={{
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                      />
                      <img
                        style={{
                          position: "absolute",
                          top: "-100px",
                          right: "-70px",
                          width: { xs: "150px", md: "233px" },
                          height: { xs: "150px", md: "233px" },
                        }}
                        src={Rounddesign}
                        alt="Round Design"
                      />
                    </Stack>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};

export default FeatureOurStrengthSection;
