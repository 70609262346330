import {
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../common/button/CustomButton";
import CarouselWithCards from "../../../common/Post/CarouselWithCards";
const Introduction = ({
  data,
  isHide,
  title,
  subTitle,
  isGrid,
  isClr,
  navi,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      style={{
        backgroundColor: isClr ? "#FFFFFF" : "#F8F8F8",
      }}
    >
      <Container>
        <Stack py={isClr ? 0 : 5}>
          <Stack>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "33px",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: "50px",
                lineHeight: "70px",
                color: "#000000",
              }}
            >
              {subTitle}
            </Typography>
          </Stack>

          {!isHide && (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "36px",
                    color: "#000000",
                  }}
                >
                  戦略立案から施策実行まで多様なケースで企業の課題解決をご支援。
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "36px",
                    color: "#000000",
                  }}
                >
                  技術力とコストパフォーマンスに高い評価をいただいています。
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={4} mt={6}>
            <CarouselWithCards posts={data} slug={"casestudies"} />
          </Grid>

          <Stack
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
              alignItems: "center",
              mt: 4,
            }}
            onClick={() => navigate("/achievements")}
          >
            <CustomButton text={"実績の一覧を見る"} />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Introduction;
