import { apiUrls } from "../../../constants";
import apiRequest from "../../apiRequest";

export function getTags() {
  return apiRequest({
    url: `${apiUrls.tags}`,
    method: "GET",
    data: undefined,
  });
}
