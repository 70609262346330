import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import CarouselWithCards from "../../common/Post/CarouselWithCards";
import OtherPosts from "../../components/blog/OtherPosts";
import CustomNewsButton from "../../components/News/CustomNewsButton";
import GradientHeader from "../../components/product/GradientHeader";
import { usePostStore } from "../../store/postStore";
const headtitle = "Blog";
const headsubTitle = "ブログ";
const headsubdec = "ブログ";

const BlogPage = () => {
  const navigate = useNavigate();
  const { loading, posts, getPosts } = usePostStore();

  const [selectedButton, setSelectedButton] = useState("すべて");
  const [title, setTitle] = useState("All");

  const handleButtonClick = (label) => {
    setSelectedButton(label);
  };

  const buttonLabels = [
    "すべて",
    "カルチャー",
    "ビジネス",
    "教育",
    "インタビュー",
  ];

  useEffect(() => {
    if (selectedButton === "カルチャー") {
      navigate("/category/culture/");
      setTitle("Culture");
      getPosts("culture");
    } else if (selectedButton === "ビジネス") {
      navigate("/category/business/");
      setTitle("Business");
      getPosts("business");
    } else if (selectedButton === "教育") {
      navigate("/category/education/");
      setTitle("Education");
      getPosts("education");
    } else if (selectedButton === "インタビュー") {
      navigate("/category/interview/");
      setTitle("Interview");
      getPosts("interview");
    } else {
      navigate("/category/all/");
      setTitle("All");
      getPosts("all");
    }
  }, [selectedButton, getPosts, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{title} - Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Buttons Section */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              display: "flex",
              gap: "16px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              py: 8,
            }}
          >
            {buttonLabels.map((label) => (
              <CustomNewsButton
                key={label}
                label={label}
                selectedButton={selectedButton}
                onClick={handleButtonClick}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ background: "#fff7f2" }}>
        <Container>
          <Box sx={{ py: 9 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
                fontFamily: "Noto Sans JP, sans-serif",
              }}
            >
              Most read articles{" "}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: {
                  xs: "18px",
                  sm: "50px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
              }}
            >
              よく読まれている記事
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                fontWeight: 400,
                fontFamily: "Noto Sans JP, sans-serif",
                mt: {
                  xs: 3,
                  sm: 6,
                },
                color: "#000000",
              }}
            >
              グローバルジャパンネットワークはグローバルビジネスとテクノロジーに
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                mb: 5,
                fontWeight: 400,
                fontFamily: "Noto Sans JP, sans-serif",
                color: "#000000",
              }}
            >
              精通した専門家が、日本企業の成功をサポートします。
            </Typography>
            <CarouselWithCards slug={"blogs"} posts={posts} loading={loading} />
          </Box>
        </Container>
      </Box>
      <Box sx={{ background: "#F8F8F8" }}>
        <OtherPosts />
      </Box>
    </>
  );
};

export default BlogPage;
