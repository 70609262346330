import {
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import CustomButton from "../../../common/button/CustomButton";



const InsightGlobalText = () => {

  return (
    <Stack backgroundColor={"#FFFFFF"}>
      <Container>
        <Stack py={5}>
          <Stack>
            <Stack>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "38px",
                  lineHeight: "53px",
                  color: "#000000",
                }}
              >
                データ分析プラットフォームで経営数字を見える化
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "38px",
                  lineHeight: "53px",
                  color: "#000000",
                }}
              >
                Insight Global
              </Typography>
            </Stack>
          </Stack>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                ノーコードで複数のデータを一元管理できます。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                よりビジネスの成長にコミットしたデータ活用ができます。
              </Typography>
            </Grid>
          </Grid>

          <Stack
            py={3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            // onClick={() => navigate("/お問い合わせ-2/")}
            onClick={() => (window.location.href = "https://insightglobal.jp/")}
          >
            <CustomButton text="詳細を見る" />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default InsightGlobalText;
