import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CircleArrow from "../../../assets/images/circleArrow.svg";
import HoverIcon from "../../../assets/images/hoverIcon.svg";
import { getFeaturedMedia, getTitle } from "../../../utils/postUtils";

const ServiceCard = ({
  index,
  service,
  slug,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      py={5}
      sx={{
        py: {
          xs: 1,
          sm: 5,
        },
        backgroundColor: slug !== "products" ? "transparent" : "transparent",
        "&:hover": {
          backgroundColor: slug !== "products" ? "#f7f7f7" : "transparent",
        },
      }}
      onClick={() =>
        navigate(
          service?.content?.rendered.match(/href="([^"]+)"/)[1]
        )
      }
    >
  <Grid container spacing={5}
   >
    <Grid item xs={12} sm={4}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "flex-start", 
        }}
      >
        <Typography
          variant="h5"
          color="textSecondary"
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            lineHeight: "24px",
            color: "#D4D4D4",
          }}
        >
          {String(Number(index) + 1).padStart(2, "0")}
        </Typography>
        <Stack sx={{width:'100%'}}>
        <img
          src={getFeaturedMedia(service)}
          alt={getTitle(service)}
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "auto", 
          }}
        />
        </Stack>
      </Stack>
    </Grid>

    <Grid item xs={12} sm={8}>
      <Box>
        <Typography
          variant="body1"
          mt={1}
          sx={{
            fontWeight: 400,
            fontSize: {
              xs: "14px",
              sm: "16px",
            },
            color: "#000000",
            lineHeight: "22px",
          }}
        >
          {new DOMParser()
            .parseFromString(service?.content?.rendered, "text/html")
            .querySelector("h2")?.textContent || ""}
        </Typography>
        
        {/* Service Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: {
              xs: "16px",
              sm: "20px",
            },
            lineHeight: "28px",
            background:
              "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginTop:1
          }}
        >
          {service?.title?.rendered}
        </Typography>

        {/* Additional Content */}
        <Typography
          variant="body1"
          mt={2}
          sx={{
            fontWeight: 400,
            fontSize: {
              xs: "14px",
              sm: "16px",
            },
            color: "#000000",
            lineHeight: "28px",
          }}
        >
          {new DOMParser()
            .parseFromString(service?.content?.rendered, "text/html")
            .querySelector("h6")?.textContent || ""}
        </Typography>

        {/* Call to Action */}
        {slug !== "products" && (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              cursor: "pointer", // Ensure pointer cursor on hover
              "&:hover": {
                "& .hover-text": {
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  transform: "translateX(5px)", // Move text slightly to the right
                },
                "& .hover-icon": {
                  content: `url(${HoverIcon})`, // Change icon image on hover
                },
              },
            }}
            onClick={() =>
              navigate(
                service?.content?.rendered.match(/href="([^"]+)"/)[1]
              )
            }
          >
            <Stack
              mt={1}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                className="hover-text"
                sx={{
                  fontWeight: 400,
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                  },
                  lineHeight: "22px",
                  color: "#000000",
                  transition: "color 0.3s ease, transform 0.3s ease",
                }}
              >
                詳細を見る
              </Typography>

              <img
                src={CircleArrow}
                className="hover-icon"
                style={{
                  paddingLeft: "1em",
                  width: "30px",
                  height: "30px",
                  transition: "transform 0.3s ease",
                }}
                onMouseEnter={(e) => (e.currentTarget.src = HoverIcon)}
                onMouseLeave={(e) => (e.currentTarget.src = CircleArrow)}
                alt=""
              />
            </Stack>
          </Grid>
        )}
      </Box>
    </Grid>
  </Grid>

    </Box>
  );
};

export default ServiceCard;
