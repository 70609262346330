import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NewsCard = ({ Cardtitle, CardDate, ButtonText, CardContent, CardIMage, RightArrow }) => {
  const navigate = useNavigate();

  return (
    <Box
      py={5}
      sx={{
        py: { xs: 1, sm: 5 },
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#f7f7f7",
        },
        cursor: "pointer", // Make it feel interactive
      }}
      onClick={() => navigate("/some-page")} // You can customize navigation here
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          {/* Image and Title Section */}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={CardIMage}
              alt={Cardtitle}
              style={{ borderRadius: "10px", width: "100%" }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                color: "#121E2C",
                position: "absolute",
                top: "55%",
                left: "55%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              {Cardtitle}
            </Typography>
          </Grid>

          {/* Date, ButtonText, and Content Section */}
          <Grid item xs={12} sm={7}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "14px", sm: "16px" },
                  color: "#000000",
                }}
              >
                {CardDate}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "12px", sm: "14px" },
                  background: "#F2F4F7",
                  borderRadius: "30px",
                  padding: "5px 14px",
                  color: "#7E7E7E",
                }}
              >
                {ButtonText}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "14px", sm: "16px" },
                color: "#000000",
              }}
            >
              {CardContent}
            </Typography>
          </Grid>

          {/* Right Arrow Section */}
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                src={RightArrow}
                alt="Right Arrow"
                style={{ borderRadius: "10px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NewsCard;
