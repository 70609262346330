import React, { useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Container,
  Typography,
} from "@mui/material";
import CustomNewsButton from "./CustomNewsButton"; // Import the new component

const ButtonGrid = () => {
  const [selectedButton, setSelectedButton] = useState("すべて");

  const handleButtonClick = (label) => {
    setSelectedButton(label);
  };

  const buttonLabels = ["すべて", "ニュース", "プレスリリース", "事例"];

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        {/* Buttons Section */}
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            display: "flex",
            gap: "16px",
            flexWrap: { xs: "wrap", md: "nowrap" },
            py: 8,
          }}
        >
          {buttonLabels.map((label) => (
            <CustomNewsButton
              key={label}
              label={label}
              selectedButton={selectedButton}
              onClick={handleButtonClick}
            />
          ))}
        </Grid>

        {/* Right-aligned select */}
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginRight: "20px",
              fontWeight: 700,
              fontSize: "20px",
              fontFamily: "Noto Sans JP, sans-serif",
            }}
          >
            年別
          </Typography>
          <FormControl sx={{ minWidth: 211 }}>
            <Select
              labelId="demo-select-label"
              id="demo-select"
              defaultValue="すべて"
              sx={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                height: "40px",
                border: "1px solid #CCCCCC",
                fontWeight: 700,
                fontSize: "14px",
                fontFamily: "Noto Sans JP, sans-serif",
              }}
            >
              <MenuItem value="option1">すべて</MenuItem>
              <MenuItem value="option2">すべて</MenuItem>
              <MenuItem value="option3">すべて</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ButtonGrid;
