import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Code10 from "../../../assets/images/code10.svg";
import Code11 from "../../../assets/images/code11.svg";
import Code12 from "../../../assets/images/code12.svg";
import Code13 from "../../../assets/images/code13.svg";
import Code14 from "../../../assets/images/code14.svg";
import Code15 from "../../../assets/images/code15.svg";
import Code2 from "../../../assets/images/code2.svg";
import Code3 from "../../../assets/images/code3.svg";
import Code4 from "../../../assets/images/code4.svg";
import Code5 from "../../../assets/images/code5.svg";
import Code6 from "../../../assets/images/code6.svg";
import Code7 from "../../../assets/images/code7.svg";
import Code8 from "../../../assets/images/code8.svg";
import Code9 from "../../../assets/images/code9.svg";
import Html from "../../../assets/images/html.svg";
import Reactimg from "../../../assets/images/react.svg";

const data = [
  { title: "Javascript", imgSrc: Code3 },
  { title: "React js", imgSrc: Reactimg },
  { title: "Php", imgSrc: Code2 },
  { title: "Python", imgSrc: Code4 },
  { title: "C#", imgSrc: Code8 },
  { title: "Node Js", imgSrc: Code5 },
  { title: "Next Js", imgSrc: Code6 },
  { title: "Microsoft", imgSrc: Code7 },
  { title: "HTML 5", imgSrc: Html },
  { title: "CSS 3", imgSrc: Code14 },
  { title: "Tailwind ", imgSrc: Code10 },
  { title: "Material UI", imgSrc: Code13 },
  { title: "Bootstrap 5", imgSrc: Code15 },
  { title: "MySQL", imgSrc: Code12 },
  { title: "Wordpress", imgSrc: Code9 },
  { title: "Shopify", imgSrc: Code11 },
];

const TechnologyAndSkills = () => {
  return (
    <Container sx={{  position: "relative" }} >
      <Stack py={5}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background:
              "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
            fontSize: { xs: "20px", sm: "24px" },
            lineHeight: { xs: "28px", sm: "33px" },

          }}
        >
          Support with abundant technology and skills
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: { xs: "30px", sm: "50px" },
            lineHeight: { xs: "40px", sm: "70px" },
            color: "#000000",
          }}
        >
          豊富な技術・スキルでサポート
        </Typography>

        <Grid container py={6}>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={3}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#FF6000",
                        fontSize: { xs: "18px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      対応可能領域
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "18px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      業務システム・Webサービスの開発
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'
                      }}
                    >
                      Webオープン系業務システムの開発
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      Webサイト(CMS)の構築
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      既存Webアプリケーションのカスタマイズ
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      AWSのサービスを活用したシステムの開発
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "18px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      iOS・Androidアプリの開発
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      スマートフォンアプリの開発
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "18px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      システム運用・保守業務
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      既存システムの運用・保守
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      既存システムの機能改修
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      Webサイトの運用・保守
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "18px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      研究開発・実証実験
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      AWSにサービスを活用したシステムのトライアル開発
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      AI/MA/RPA領域のトライアル開発
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 400,
                        lineHeight: "32px",
                        fontFamily:'Inter, sans-serif'

                      }}
                    >
                      各種調査、分析、検証
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: "#FF6000",
                      fontSize: { xs: "18px", sm: "20px" },
                      fontWeight: 700,
                      lineHeight: "28px",
                    }}
                  >
                    対応可能な開発言語
                  </Typography>
                </Stack>

                <Grid container spacing={6} mt={3}>
                  {data.map((item, index) => (
                    <Grid item xs={6} sm={3} key={index}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "contain",
                          }}
                          src={item.imgSrc}
                          alt={`${item.title} logo`} // Improved alt text
                        />
                        <Typography
                          variant="body2"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontWeight: 400,
                            textAlign: "center",
                            fontSize: { xs: "12px", sm: "16px" },
                            lineHeight: { xs: "20px", sm: "22px" },
                            marginTop:2
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default TechnologyAndSkills;
