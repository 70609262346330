import { toast } from "react-toastify";

export const notify = (msg, type = "error", options = {}) => {
  toast[type](msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    progressStyle: { background: type === "error" ? "red" : "#24003d" },
    ...options,
  });
};
