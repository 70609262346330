import { Card, CardContent, CardMedia, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { usePostStore } from "../../store/postStore";
import {
  getAchievementCategory,
  getCategory,
  getExcerpt,
  getFeaturedMedia,
  getFormattedDate,
  getTitle,
} from "../../utils/postUtils"; 

export default function PostCard({ post, slug, categories, achievementsLoading }) {
  const navigate = useNavigate();
  const { setCategory } = usePostStore();

  return (
    // <a href={post.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

    <Card
      sx={{
        backgroundColor: "#F6F6F6",
        borderRadius: 4,
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        transition: "box-shadow 0.3s",
        cursor: "pointer",
        height: 395,
        "&:hover": {
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
        },
      }}
      onClick={() => {
        if (!achievementsLoading) {
          setCategory(slug === 'achievementservice' || slug === 'category' ? getAchievementCategory(post, slug, categories) : getCategory(post, slug)); navigate(`/${post?.title?.rendered}`, {
            state: { propName: "achievements" },
          })
        }
      }}
    >

      {achievementsLoading ? (
        <Skeleton sx={{ minHeight: 190 }} animation="wave" variant="rectangular" />
      ) : (<CardMedia
        component="img"
        image={getFeaturedMedia(post)}
        alt={getTitle(post)}
        sx={{
          height: 200, // Adjust height as needed
          objectFit: "cover",
        }}
      />)}
      <CardContent>
        {/* Category and Date */}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {
            achievementsLoading ? (
              <Skeleton
                animation="wave"
                height={30}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: "#FF600030",
                  padding: "4px 8px",
                  borderRadius: "16px",
                  marginTop: "10px",
                  color: "#f18b22",
                  fontWeight: 700,
                  cursor: "pointer",
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: { xs: "12px", sm: "12px" },
                  textTransform: "capitalize",
                }}
              >
                {slug === 'achievementservice' || slug === 'category' ? getAchievementCategory(post, slug, categories) : getCategory(post, slug)}
              </Typography>)}
          {slug === 'blogs' &&
            <>
              {
                achievementsLoading ? (
                  <Skeleton
                    animation="wave"
                    height={30}
                    width="20%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Typography
                    variant="caption"
                    sx={{
                      backgroundColor: "#ECECEC",
                      padding: "4px 6px",
                      borderRadius: "16px",
                      marginTop: "16px",
                      color: "#000000",
                      fontWeight: 400,
                      cursor: "pointer",
                      fontFamily: "Noto Sans JP, sans-serif",
                      fontSize: { xs: "12px", sm: "10px" },

                    }}
                  >
                    {getFormattedDate(post)}
                  </Typography>)}</>}
        </Grid>

        {/* post Title */}
        {achievementsLoading ? (
          <React.Fragment>
            <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
          </React.Fragment>
        ) : (
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#000000",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,  // Limit text to 2 lines
              overflow: "hidden",
              textOverflow: "ellipsis"

            }}
          >
            {getTitle(post)}
          </Typography>)}

        {/* post Excerpt */}
        {achievementsLoading ? (
          <React.Fragment>
            <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
          </React.Fragment>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: "#898989",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "25px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,  // Limit text to 2 lines
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: getExcerpt(post),
              }}
            ></p>
          </Typography>)}
      </CardContent>
    </Card>
    // </a>
  );
}
