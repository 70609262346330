// postUtils.js
import moment from "moment";

const DEFAULT_IMAGE_URL = "../assets/images/default-post.png";
const prodUrl = 'https://wpgjnetwork.azurewebsites.net/';

// Function to get the featured media URL
export const getFeaturedMedia = (post) => {
  if (
    post._embedded &&
    post._embedded["wp:featuredmedia"] &&
    post._embedded["wp:featuredmedia"][0]
  ) {
    return prodUrl + post._embedded["wp:featuredmedia"][0].source_url;
  }
  return DEFAULT_IMAGE_URL;
};

// Function to get the category name
export const getCategoryName = (post, slug) => {
  if (
    post._embedded &&
    post._embedded["wp:term"] &&
    post._embedded["wp:term"][0]
  ) {
    // Filter out categories that have the same slug
    const categorylists = post._embedded["wp:term"][0].filter(
      (category) => category.slug !== slug
    );

    // Return the name of the first category that does not match the slug
    if (categorylists.length > 0) {
      return categorylists[categorylists.length - 1].name;
    }
  }

  return "";
};

export const getCategory = (post, slug) => {
  if (
    post._embedded &&
    post._embedded["wp:term"] &&
    post._embedded["wp:term"][0]
  ) {
    // Filter out categories that have the same slug
    const categorylists = post._embedded["wp:term"][0].filter(
      (category) => category.slug === slug
    );

    // Return the name of the first category that does not match the slug
    if (categorylists.length > 0) {
      return categorylists[categorylists.length - 1].name;
    }
  }

  return "";
};

export const getAchievementCategory = (post, slug, categories) => {

  if (
    post._embedded &&
    post._embedded["wp:term"] &&
    post._embedded["wp:term"][0]
  ) {
    const categorylists = post._embedded["wp:term"][0].filter(
      (category) => category.slug !== slug
    );

    const categoryNames = categories.map(category => category.name);

    const matchingCategories = categorylists
      .filter(apiCategory => categoryNames.includes(apiCategory.name))
      .map(apiCategory => apiCategory.name);

    return matchingCategories[0]

  }

  return "";

};
// Function to get the title
export const getTitle = (post) => {
  return post.title && post.title.rendered ? post.title.rendered : "Untitled";
};

// Function to get the formatted date
export const getFormattedDate = (post) => {
  return post.date ? moment(post.date).format("YYYY-MM-DD") : "No date available";
};

// Function to get the excerpt
export const getExcerpt = (post) => {
  return post.excerpt && post.excerpt.rendered
    ? post.excerpt.rendered.replace(/\[.*?\]/g, '')
    : "<p>No description available</p>";
};
