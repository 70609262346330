import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => {

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" py={5}>
      {/* Previous Page Button */}
      {totalPages > 1 &&
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>}

      {/* Page Numbers */}
      {Array.from({ length: totalPages }, (_, index) => (
        <Box
          key={index + 1}
          mx={1}
          sx={{
            backgroundColor:
              currentPage === index + 1 ? "#FF6000" : "transparent",
            color: currentPage === index + 1 ? "#fff" : "#898989",
            borderRadius: "50%",
            width: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "14px",
          }}
          onClick={() => onPageChange(index + 1)}
        >
          <Typography>{index + 1}</Typography>
        </Box>
      ))}

      {/* Next Page Button */}
      {totalPages > 1 &&
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>}
    </Box>
  );
};

export default PaginationComponent;
