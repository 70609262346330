import { apiUrls } from "../../../constants";
import apiRequest from "../../apiRequest";

async function getCategoryId(slug) {
  const response = await apiRequest({
    url: `${apiUrls.categories}?per_page=100`,
    method: "GET",
    data: undefined,
  });
  const category = response?.data.find((cat) => cat.slug === slug);
  return category ? category.id : null;
}

// Updated getPosts function
export async function getPosts(slug) {
  const categoryId = await getCategoryId(slug);
  if (categoryId) {
    return apiRequest({
      url: `${apiUrls.posts}?categories=${categoryId}&_embed&orderby=date&order=asc&per_page=100`,
      method: "GET",
      data: undefined,
    });
  } else {
    return {
      success: false,
      data: [],
    };
    // throw new Error("Blog category not found");
  }
}

export async function getAchievements(slug) {
  const categoryId = await getCategoryId(slug);
  if (categoryId) {
    return apiRequest({
      url: `${apiUrls.posts}?categories=${categoryId}&_embed&orderby=date&order=asc&per_page=100&_=${new Date().getTime()}`,
      method: "GET",
      data: undefined,
    });
  } else {
    return {
      success: false,
      data: [],
    };
    // throw new Error("Blog category not found");
  }
}

export async function getCategories(slug) {
  const categoryId = await getCategoryId(slug);
  const response = await apiRequest({
    url: `${apiUrls.categories}?per_page=100&parent=${categoryId}`,
    method: "GET",
    data: undefined,
  });
  const categoryNames = response?.data.map(category => ({ name: category.name, slug: category.slug }));
  return categoryNames;
}

export async function getPostDetail(id) {
  const response = apiRequest({
    url: `${apiUrls.posts}/?search=${encodeURIComponent(id)}`,
    method: "GET",
    data: undefined,
  });
  return response;
}
