import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Arrowforward from "../../assets/images/arrowforward.svg";
import Magicproduct from "../../assets/images/magicproduct.svg";
import Otherhouses from "../../assets/images/otherhouses.svg";

const ServiceHeader = () => {
  return (
    <Stack
      sx={{
        backgroundImage: `url(${Magicproduct})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "300px",
        color: "#000000",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container>
        <Stack py={5}>
          <Stack>
            <Stack>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "33px",
                  fontFamily: 'Noto Sans JP, sans-serif'
                }}
              >
                Our Services
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "50px",
                  lineHeight: "70px",
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                   fontFamily: 'Noto Sans JP, sans-serif'
                }}
              >
                当社のサービス
              </Typography>

              <Stack
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src={Otherhouses}
                    alt="Other houses"
                  />
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src={Arrowforward}
                    alt="Arrow forward"
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#898989",
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                      fontFamily: 'Noto Sans JP, sans-serif'
                    }}
                  >
                    サービス
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ServiceHeader;
