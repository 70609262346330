import { Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../common/button/CustomButton";


const IntroductionFlow = () => {

  const [currentStep] = useState(1);

  return (
    <Stack backgroundColor={"#FFFFFF"}>
      <Container>
        <Stack py={6}>
          <Stack>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background:
                  "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "33px",
              }}
            >
              Introduction flow
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: "50px",
                lineHeight: "70px",
                color: "#000000",
              }}
            >
              導入の流れ
            </Typography>
          </Stack>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                まずはお気軽にお問い合わせください。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                貴社のご要望に応じて、プランをご提案させていただきます。
              </Typography>
            </Grid>
          </Grid>

          {/* Custom Stepper */}


          <Stack
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <ul
              className="stepper"
              style={{
                display: "flex",
                margin: "20px",
                padding: 0,
                listStyle: "none",
                position: "relative",
                width: "70rem",
              }}
            >
              <li
                style={{
                  flex: "100%",
                  padding: "20px 20px 20px 40px",
                  background: "#FF600012",
                  margin: "0 0 0 -19px",
                  position: "relative",
                  clipPath:
                    "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                  ...(currentStep === 0
                    ? { fontWeight: "bold", background: "#FF600012" }
                    : {}),
                  ...(currentStep > 0 ? { background: "#FF600012" } : {}),
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    color: "#FF6000",
                  }}
                >
                  01 お問い合わせ
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "0",
                    height: "0",
                    borderLeft: "20px solid #FF600012",
                    borderTop: "20px solid transparent",
                    borderBottom: "20px solid transparent",
                  }}
                ></div>
              </li>

              <li
                style={{
                  flex: "100%",
                  padding: "20px 20px 20px 40px",
                  background: "#FF600033",
                  margin: "0 0 0 -19px",
                  position: "relative",
                  clipPath:
                    "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                  ...(currentStep === 1
                    ? { fontWeight: "bold", background: "#FF600033" }
                    : {}),
                  ...(currentStep > 1 ? { background: "#FF600033" } : {}),
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    color: "#FF6000",
                  }}
                >
                  02 ヒアリング・ご提案
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "0",
                    height: "0",
                    borderLeft: "20px solid #FF600033",
                    borderTop: "20px solid transparent",
                    borderBottom: "20px solid transparent",
                  }}
                ></div>
              </li>

              <li
                style={{
                  flex: "100%",
                  padding: "20px 20px 20px 40px",
                  background: "#FF6000B2",
                  margin: "0 0 0 -19px",
                  position: "relative",
                  clipPath:
                    "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                  ...(currentStep === 2
                    ? { fontWeight: "bold", background: "#FF6000B2" }
                    : {}),
                  ...(currentStep > 2 ? { background: "#FF6000B2" } : {}),
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  03 お見積り
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "0",
                    height: "0",
                    borderLeft: "20px solid #FF6000B2",
                    borderTop: "20px solid transparent",
                    borderBottom: "20px solid transparent",
                  }}
                ></div>
              </li>

              <li
                style={{
                  flex: "100%",
                  padding: "20px 20px 20px 40px",
                  background: "#FF6000",
                  margin: "0 0 0 -19px",
                  position: "relative",
                  clipPath:
                    "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                  ...(currentStep === 3
                    ? { fontWeight: "bold", background: "#FF6000" }
                    : {}),
                  ...(currentStep > 3 ? { background: "#FF6000" } : {}),
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  04 ご契約・導入
                </Typography>
              </li>
            </ul>
          </Stack>

          <Stack
            mt={4}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <li
              style={{
                flex: "100%",
                padding: "20px 20px 20px 40px",
                background: "#FF600012",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                clipPath:
                  "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                ...(currentStep === 0
                  ? { fontWeight: "bold", background: "#FF600012" }
                  : {}),
                ...(currentStep > 0 ? { background: "#FF600012" } : {}),
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: "#FF6000",
                }}
              >
                01 お問い合わせ
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "-20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "0",
                  height: "0",
                  borderLeft: "20px solid #FF600012",
                  borderTop: "20px solid transparent",
                  borderBottom: "20px solid transparent",
                }}
              ></div>
            </li>

            <li
              style={{
                flex: "100%",
                padding: "20px 20px 20px 40px",
                background: "#FF600033",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                clipPath:
                  "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                ...(currentStep === 1
                  ? { fontWeight: "bold", background: "#FF600033" }
                  : {}),
                ...(currentStep > 1 ? { background: "#FF600033" } : {}),
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: "#FF6000",
                }}
              >
                02 ヒアリング・ご提案
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "-20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "0",
                  height: "0",
                  borderLeft: "20px solid #FF600033",
                  borderTop: "20px solid transparent",
                  borderBottom: "20px solid transparent",
                }}
              ></div>
            </li>

            <li
              style={{
                flex: "100%",
                padding: "20px 20px 20px 40px",
                background: "#FF6000B2",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                clipPath:
                  "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                ...(currentStep === 2
                  ? { fontWeight: "bold", background: "#FF6000B2" }
                  : {}),
                ...(currentStep > 2 ? { background: "#FF6000B2" } : {}),
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                03 お見積り
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "-20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "0",
                  height: "0",
                  borderLeft: "20px solid #FF6000B2",
                  borderTop: "20px solid transparent",
                  borderBottom: "20px solid transparent",
                }}
              ></div>
            </li>

            <li
              style={{
                flex: "100%",
                padding: "20px 20px 20px 40px",
                background: "#FF6000",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                clipPath:
                  "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                ...(currentStep === 3
                  ? { fontWeight: "bold", background: "#FF6000" }
                  : {}),
                ...(currentStep > 3 ? { background: "#FF6000" } : {}),
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                04 ご契約・導入
              </Typography>
            </li>
          </Stack>

          <Stack
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            onClick={() =>
              (window.location.href = "https://insightglobal.jp/plan")
            }
          >
            <CustomButton text="詳細を見る" />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default IntroductionFlow;
