import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Container,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";

const AccordionScreen = ({data, title}) => {
  return (
    <Stack
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Container>
        <Stack py={5}>
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: { xs: "30px", md: "38px" }, // Responsive font size
                lineHeight: { xs: "40px", md: "53px" }, // Responsive line height
                color: "#000000",
              }}
            >
              {title}
            </Typography>
          </Stack>

          {data.map((item, index) => (
            <Stack
              px={{ xs: 2, md: 15 }} // Responsive padding
              key={index}
              mt={2}
            >
              <Accordion
                style={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                      fontWeight: 400,
                      lineHeight: "22px",
                    }}
                  >
                   {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                {item.answer}
                </AccordionDetails>
              </Accordion>
            </Stack>
          ))}
        </Stack>
      </Container>
    </Stack>
  );
};

export default AccordionScreen;
