import {
  Container,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import Flexible1 from "../../../assets/images/flexible1.svg";
import Flexible2 from "../../../assets/images/flexible2.svg";

const data = [
  {
    no: "01",
    title: "ラボ開発",
    description:
      "ラボ開発LAB DEVELOPMENT ラボ形式で貴社に専属のＩＴエンジニアをインド側でアサインし、長期間に渡りノウハウを蓄積しながら、低コストで運用が可能です。仕様や納期の変更、同時に複数案件の進行等、臨機応変に柔軟に対応致します",
    imgSrc: Flexible1,
  },
  {
    no: "02",
    title: "受託型開発",
    description:
      "各プロジェクトごとに、貴社のご要望にお応えして、チームを編成いたします。プロジェクトの内容に応じて、最適なチーム編成を行い、お見積りにご同意いただいた後に、業務を行います。特定の技術へのご要望や、短期間の小さいプロジェクトでもご対応が可能です。",
    imgSrc: Flexible2,
  },
];

const FlexibleDevelopmentSystem = () => {
  return (
    <Container >
      <Stack py={5}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            background:
              "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
            fontSize: { xs: "20px", md: "24px" }, // Responsive font size
            lineHeight: { xs: "28px", md: "33px" },
          }}
        >
          Flexible development system
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: { xs: "30px", md: "50px" }, // Responsive font size
            lineHeight: { xs: "40px", md: "70px" },
            color: "#000000",
          }}
        >
          柔軟な開発体制
        </Typography>

        <Grid
          container
          py={6}
          justifyContent="center"
          alignItems="center"
        >
          {data.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#FF600033",
                        fontSize: { xs: "50px", md: "90px" }, // Responsive font size
                        fontWeight: 700,
                        lineHeight: { xs: "70px", md: "126px" },
                      }}
                    >
                      {item.no}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={10}>
                  <Stack
                    direction={"column"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Stack mt={3}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: { xs: "16px", md: "20px" }, // Responsive font size
                          fontWeight: 700,
                          lineHeight: { xs: "24px", md: "32px" },
                        }}
                      >
                        {item.title}
                      </Typography>

                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: { xs: "14px", md: "20px" }, // Responsive font size
                          fontWeight: 400,
                          lineHeight: { xs: "20px", md: "32px" },
                          fontFamily:"Inter, sans-serif"
                        }}
                      >
                        {item.description}
                      </Typography>

                      <Stack
                        py={3}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "auto", // Make height auto to maintain aspect ratio
                            maxHeight: "335px",
                            objectFit: "contain",
                          }}
                          src={item.imgSrc}
                          alt="Frame 1002"
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};

export default FlexibleDevelopmentSystem;
