import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Rounddesign from "../../../assets/images/rounddesign.svg";

const MeetYourNeeds = ({ data, title, subTitle }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack sx={{ position: "relative", overflow: "hidden" }}>
      <Container >
        <Stack py={5}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              background:
                "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "33px",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: "50px",
              lineHeight: "70px",
              color: "#000000",
            }}
          >
            {subTitle}
          </Typography>

          <Grid container py={6}>
            {data.map((item, index) => (
              <Grid key={index} item xs={12} sx={{ mb: 4, borderRadius: 5 }}>
                {isMobile ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          padding: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "32px",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Stack
                        sx={{
                          display: "flex",

                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            height: "235px",
                            zIndex: 1,
                            borderRadius: 15,
                          }}
                          src={item.imgSrc}
                          alt="Frame 1002"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                ) : index % 2 === 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            height: "323px",
                            width:'100%',
                            zIndex: 1,
                            borderTopLeftRadius: 15,
                            borderBottomLeftRadius: 15,
                          }}
                          src={item.imgSrc}
                          alt="Frame 1002"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          paddingRight: 5,
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "32px",
                            fontFamily:'Inter, sans-serif'
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          paddingLeft: 5,
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "32px",
                            fontFamily:'Inter, sans-serif'
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            height: "323px",
                            width:'100%',
                            zIndex: 1,
                            borderTopRightRadius: 15,
                            borderBottomRightRadius: 15,
                          }}
                          src={item.imgSrc}
                          alt="Frame 1002"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Box
          component="img"
          src={Rounddesign}
          alt="Round Design"
          sx={{
            position: "absolute",
            top: 20,
            left: -100,
            zIndex: -1,
            width: "182px",
            height: "182px",
          }}
        />
      </Container>
    </Stack>
  );
};

export default MeetYourNeeds;
