import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Colorarrow from "../../assets/images/colorarrow.svg";
import Frame1004 from "../../assets/images/Frame1004.svg";
import Frame1005 from "../../assets/images/Frame1005.svg";
import User from "../../assets/images/User.svg";
import GradientHeader from "../../components/product/GradientHeader";
const headData = {
  title: "About Us",
  subTitle: "会社情報",
  subdesc: "会社情報",
};

const dataForIntroductionFlow = [
  {
    title: "熊本 浩明",
    description:
      "公認会計士。住友銀行(現三井住友銀行)金融機関勤後、プライスウォーターハウスクーパース（PwC）東京事務所、同香港事務所でコンサルタントとして15年の経験。",
    imgSrc: Frame1004,
  },
  {
    title: "ヴィカス クマール",
    description:
      "弁護士と原価会計士（インド）の資格を持つ。インドの弁護士事務所で、日系企業を含む外資系企業の法務、税務、コンプライアンス、海外事業について幅広いアドバイスを行います。",
    imgSrc: Frame1005,
  },
];

const aboutTitle = "キーメンバー";
const companyInfo = {
  overview: {
    title: "グローバルジャパンネットワークとは",
    description:
      "グローバルジャパンネットワークは、優秀なグローバルIT人材を活用し、上流から下流まで見据えたシステム開発を提供しています。オフショア機能を活かした高品質な業務システムとWEBシステム開発が強みです。公認会計士や弁護士がチームをマネジメントし、コンサルティング+ITで企業をサポートしています。",
  },
  details: [
    {
      label: "社名",
      value:
        "株式会社グローバルジャパンネットワーク  Global Japan Network Limited",
    },
    { label: "設立", value: "2016年5月13日" },
    { label: "電話", value: "03-6264-0803" },
    { label: "E-mail", value: "info@g-japan.com" },
    { label: "URL", value: "https://gjnetwork.jp" },
    { label: "免許番号・派遣番号", value: "13−ユ−310378 派13-314366" },
  ],
  subDetails: [
    {
      label: "システム開発",
      value1: "受託開発",
      value2: "ラボ開発",
    },
    {
      label: "グローバル経営管理ツール(Insight Global)",
      value1: "グローバル経営管理強化",
      value2: "海外事業モニタリング",
      value3: "海外拠点とのコミュニケーションの円滑化",
    },
    {
      label: "IT人材育成",
      value1: "Insight IT人材の育成",
      value2: "海外IT人材紹介",
      value3: "海外IT人材派遣 Global導入支援",
    },
  ],
};

const content = [
  {
    title: "プロフェッショナルの誓い：東日本大震災からの再生",
    paragraphs: [
      "私たちの創業ストーリーは、プロフェッショナルとしての熱い思いから始まりました。昔から「プロフェッショナルになりたい」という強い想いを胸に、銀行や会計事務所で経験を積んできました。",
      "しかし、東日本大震災が起こり、私たちが取り組んでいたプロジェクトは一時停止を余儀なくされました。その混乱の中で、自分が本当にやりたいこと、そしてプロとしての経験を誰かのために役立てるべきだという強い思いが芽生え、独立を決意しました。",
    ],
  },
  {
    title: "日本の未来への挑戦：中小企業のグローバル化",
    paragraphs: [
      "私たちは、独立を決めた際に自問した問いの答えとして、日本の中小企業を支援することを決意しました。日本の企業の99％以上が中小企業であり、彼らが国の発展を支える重要な存在だからです。しかし、中小企業のグローバル化とIT化の進展が遅れ、国際競争力の低下が懸念されていました。",
      "そんな中、インド出身のヴィカスさんとの出会いがありました。彼から、海外には高いITスキルを持った若者が多く存在し、彼らの力が日本企業の競争力向上に不可欠だということを知りました。その確信のもと、私たちはヴィカスさんと共にITビジネスを立ち上げることを決意しました。",
    ],
  },
  {
    title: "未来への情熱：日本企業の強化へ",
    paragraphs: [
      "今では、海外のITエンジニアや日本人プロフェッショナルと連携し、中小企業の海外展開やDX促進などの支援を行っています。世界中の人々との交流の中で、私たちは「まだまだ世界は日本を待っている」と感じています。",
      "これからも、「日本企業をもっと強く！日本の底力を開花させる！」という熱い思いを胸に、私たちは一丸となって中小企業をサポートし、日本の未来への挑戦を続けていきます！",
    ],
  },
];

const contentTwo = [
  {
    title: "日本に魅了されて：未知の国からの出会い",
    paragraphs: [
      "私たちの物語は、インドの法律事務所での経験から始まりました。1年半ほど経った頃、日本企業とのプロジェクトに参加したことがきっかけで、初めて日本と出会いました。当時、ソニーやホンダなどの日本製品は身近にありましたが、日本についての知識はほとんどありませんでした。",
      "しかし、実際に日本人と共に働くうちに、日本の魅力にどっぷりとはまってしまいました。日本人は勤勉で時間に正確、計画性があり、約束を守る姿勢が素晴らしいと感じました。これまでさまざまな国の人々と働いてきましたが、その中で日本人の一貫した働きぶりには感銘を受けました。その後も、日本の歴史や文化、技術力など、さまざまな面で日本に惹かれ、より深く関わりたいと思うようになりました。",
    ],
  },
  {
    title: "夢の実現：日本と世界の架け橋に",
    paragraphs: [
      "インドでの経験を経て、日本企業の素晴らしさと課題に直面しました。その中で、日本企業のグローバル化とIT化の遅れが課題であることを痛感しました。しかし、その課題こそがチャンスであると信じました。",
      "日本企業がグローバル市場で成功するためには、異文化やグローバル基準を取り入れることが不可欠です。私たちはその架け橋となり、日本企業のIT化、グローバル化、経営改善などを支援しています。新しい挑戦には誰もが戸惑いや不安を感じるでしょうが、私たちの経験と信念をもって、日本の皆さんを世界の舞台へと導いていきます。",
    ],
  },
  {
    title: "未来への情熱：日本の発展への貢献",
    paragraphs: [
      "今後も、「世界と日本の真の融合を進め、日本の中小企業をもっと強く！」という信念を胸に、日本の発展に貢献していきたいと思います。",
    ],
  },
];

const tabs = [
  { title: "会社情報" },
  { title: "代表メッセージ" },
  { title: "企業理念" },
  { title: "沿革" },
];

const AboutUsCompany = () => {
  const location = useLocation();
  const { propName } = location.state || {};
  const HEADER_OFFSET = 100;
  const [tab, setTab] = useState("会社情報");
  const overviewRef = useRef(null);
  const messageRef = useRef(null);
  const philosophyRef = useRef(null);
  const historyRef = useRef(null);

  const sectionsRef = useMemo(() => ({
    会社情報: overviewRef,
    代表メッセージ: messageRef,
    企業理念: philosophyRef,
    沿革: historyRef,
  }), [overviewRef, messageRef, philosophyRef, historyRef]);

  const scrollToSection = (ref, isMessageSection) => {
    if (ref.current) {
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - HEADER_OFFSET;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  // Intersection Observer for setting the active tab
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTab(entry.target.id);
        }
      });
    });

    Object.values(sectionsRef).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionsRef).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionsRef]);

  // Scroll to section based on propName
  useEffect(() => {
    if (propName === "ミッション") {
      setTab("企業理念");
      scrollToSection(philosophyRef, false);
    } else if (propName === "経営メンバー") {
      setTab("代表メッセージ");
      scrollToSection(messageRef, true); // Set true for message section
    } else if (propName === "会社概要") {
      setTab("会社情報");
      scrollToSection(overviewRef, false);
    }
  }, [propName]);

  return (
    <>
      <Helmet>
        <title>
          優秀なインド人ＩＴ人材を活用して、 |
          貴社のビジネスの成長をサポート致します。
        </title>
      </Helmet>
      <GradientHeader
        title={headData.title}
        subTitle={headData.subTitle}
        subdesc={headData.subdesc}
      />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          // mt={8}
          style={{ position: "relative", minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              minHeight: { xs: "0vh", sm: "100vh" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
                alignItems: "flex-start",
                height: "281px",
                width: "260px",
                backgroundColor: "#F5F5F5",
                borderRadius: 3,
                position: "sticky",
                top: "100px",
                // zIndex: 9999,
                overflow: "hidden",
                paddingBottom: "20px",
                pointerEvents: "auto",
                marginTop: 5,
              }}
            >
              {tabs.map((item, index) => (
                <Stack
                  px={4}
                  key={index}
                  spacing={2}
                  onClick={() => {
                    setTab(item.title);
                    sectionsRef[item.title].current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  direction={"row"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {tab === item.title && (
                    <img
                      src={Colorarrow}
                      alt={"Colorarrow"}
                      style={{ height: "20px", width: "20px" }}
                    />
                  )}

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: "16px", sm: "20px" },
                      lineHeight: { xs: "20px", sm: "28px" },
                      color: tab === item.title ? "#FF6000" : "#898989",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Container>
              <Grid item xs={12}>
                <Box
                  ref={overviewRef}
                  id="会社情報"
                  sx={{ height: "35px", visibility: "hidden" }}
                />
              </Grid>
            </Container>
            <Stack mt={5}>
              {/* {tab === "会社情報" && (
                    <> */}
              {/* <Box ref={overviewRef} id="会社情報" sx={{ height: "1px", visibility: "hidden", }} /> */}

              <Stack>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "24px", sm: "38px" },
                    lineHeight: { xs: "32px", sm: "53px" },
                    color: "#000000",
                  }}
                >
                  {companyInfo.overview.title}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "16px", sm: "20px" },
                    fontWeight: 400,
                    lineHeight: { xs: "24px", sm: "36px" },
                    color: "#000000",
                  }}
                >
                  {companyInfo.overview.description}
                </Typography>
              </Stack>

              <Stack py={5}>
                <Divider />
              </Stack>

              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "38px" },
                  lineHeight: { xs: "32px", sm: "53px" },
                  color: "#000000",
                }}
              >
                会社概要
              </Typography>
              {companyInfo.details.map((detail, index) => (
                <Stack
                  mt={4}
                  key={index}
                  direction={"row"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", md: "28px" },
                      color: "#000000",
                    }}
                  >
                    {detail.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", md: "22px" },
                      color: "#000000",
                      width: "310px",
                    }}
                  >
                    {detail.value}
                  </Typography>
                </Stack>
              ))}

              <Stack py={5}>
                <Divider />
              </Stack>

              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "38px" },
                  lineHeight: { xs: "32px", sm: "53px" },
                  color: "#000000",
                }}
              >
                事業概要
              </Typography>
              {companyInfo.subDetails.map((detail, index) => (
                <Stack mt={6} key={index} spacing={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", md: "28px" },
                      color: "#000000",
                    }}
                  >
                    {detail.label}
                  </Typography>
                  <Stack spacing={4}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: { xs: "16px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", md: "22px" },
                        color: "#000000",
                        width: "310px",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#000000",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      {detail.value1}
                    </Typography>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", md: "22px" },
                        color: "#000000",
                        width: "310px",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#000000",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      {detail.value2}
                    </Typography>
                  </Stack>

                  {detail.value3 && (
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", md: "22px" },
                        color: "#000000",
                        width: "330px",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#000000",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      {detail.value3}
                    </Typography>
                  )}
                </Stack>
              ))}
              <Stack py={5}>
                <Divider />
              </Stack>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "38px" },
                  lineHeight: { xs: "32px", sm: "53px" },
                  color: "#000000",
                }}
              >
                アクセス
              </Typography>
              <Stack mt={4} spacing={3}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: 700,
                    lineHeight: { xs: "24px", md: "28px" },
                    color: "#000000",
                  }}
                >
                  本店所在地
                </Typography>
                <Stack spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", md: "22px" },
                      color: "#000000",
                      width: "310px",
                    }}
                  >
                    〒104-0061
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", md: "22px" },
                      color: "#000000",
                    }}
                  >
                    東京都中央区銀座２-１４-１ 森山ビル3階（MAP）
                  </Typography>
                </Stack>
                {/* <iframe
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.211854178884!2d139.76843051521567!3d35.67178518019651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188be1ab451aeb%3A0x1f869a3eb71a49b3!2zSmFwYW4sIOOAkjEwNC0wMDYxIFRva3lvLCBDaHVvIENpdHksIEdpbnphLCAyLWNoxY1tZeKIkjE04oiSMSDmo67lsbHjg5Pjg6sgM-majg!5e0!3m2!1sen!2sin!4v1627899822685!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  allowFullScreen
                  loading="lazy"
                  title="Google Map"
                /> */}
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.211854178884!2d139.76843051521567!3d35.67178518019651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188be1ab451aeb%3A0x1f869a3eb71a49b3!2zSmFwYW4sIOOAkjEwNC0wMDYxIFRva3lvLCBDaHVvIENpdHksIEdpbnphLCAyLWNoxY1tZeKIkjE04oiSMSDmo67lsbHjg5Pjg6sgM-majg!5e0!3m2!1sen!2sin!4v1627899822685!5m2!1sen!2sin"
                    allowFullScreen
                    loading="lazy"
                    title="Google Map"
                  />
                </div>
              </Stack>

              <Stack mt={4} spacing={3} mb={5}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: 700,
                    lineHeight: { xs: "24px", md: "28px" },
                    color: "#000000",
                  }}
                >
                  インド事務所
                </Typography>
                <Stack spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", md: "22px" },
                      color: "#000000",
                    }}
                  >
                    – Global Japan DX Labs Private Limited No. 110, Mahatma
                    Gandhi Road
                    <br />
                    Nungambakkam, Chennai 600 034（MAP）
                  </Typography>
                </Stack>
                <Box sx={{ height: "1px", visibility: "hidden" }} />
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5885301012213!2d80.24454541477161!3d13.06184279079669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52666905786707%3A0x1fbcde3a184e72a2!2s110%2C%20Uthamar%20Gandhi%20Rd%2C%20Subba%20Road%20Avenue%2C%20Nungambakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600034!5e0!3m2!1sen!2sin!4v1627900061355!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowFullScreen
                    loading="lazy"
                    title="Google Map"
                  />
                </div>
              </Stack>
              {/* </>
                  )} */}

              {/* {tab === "代表メッセージ" && (
                    <> */}

              <Box ref={messageRef} id="代表メッセージ">
                <Stack spacing={3} mt={10}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "24px", sm: "38px" },
                      lineHeight: { xs: "32px", sm: "53px" },
                      color: "#000000",
                    }}
                  >
                    代表メッセージ
                  </Typography>
                  <Stack>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: { xs: "24px", sm: "36px" },
                        color: "#000000",
                      }}
                    >
                      プロフェッショナル、グローバル人材、最高のクオリティ。
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: { xs: "24px", sm: "36px" },
                        color: "#000000",
                      }}
                    >
                      日本企業のグローバル化とDX化の推進を強力にサポート
                    </Typography>
                  </Stack>

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", sm: "28px" },
                      color: "#000000",
                    }}
                  >
                    今後の10年、20年を見据えると、
                    経済規模でインドが日本を抜き去る日が必ずやってきます。
                    日本の10倍以上の14億人 の人口を有し、平均年齢が28歳(※)、
                    アジアで最も高い経済成長を続けているインド。
                    そのインド人材のパワーを、 日本
                    企業のなかに組み込みながら世界に挑戦することが、
                    今後グローバルの競争を勝ち抜くための道筋となります。 私達
                    は、インドと日本をつなぐ架け橋になりたいと思います。
                  </Typography>

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", sm: "28px" },
                      color: "#000000",
                    }}
                  >
                    英語をネイティブに話し、
                    世界最高水準の頭脳を持ち、変わりゆく環境に適応力を発揮するインド人と共に、グローバ
                    ルビジネスに挑戦しませんか。ぜひ将来を見据えた第一歩として、
                    日本本社でのインド人採用をご検討ください。 インド人採用で、
                    グローバルビジネスをもっと身近に!
                  </Typography>

                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", sm: "28px" },
                        color: "#000000",
                      }}
                    >
                      株式会社グローバルジャパン
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", sm: "28px" },
                        color: "#000000",
                      }}
                    >
                      熊本 浩明
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", sm: "28px" },
                        color: "#000000",
                      }}
                    >
                      ヴィカスクマールバフナ
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Stack py={5}>
                <Divider />
              </Stack>
              <Stack
                style={{
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Container>
                  <Stack py={0}>
                    <Stack>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          background:
                            "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          fontWeight: 700,
                          fontSize: "24px",
                          lineHeight: "33px",
                        }}
                      ></Typography>
                      <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                          fontWeight: 700,
                          fontSize: "38px",
                          lineHeight: "53px",
                          color: "#000000",
                        }}
                      >
                        {aboutTitle}
                      </Typography>
                    </Stack>
                    <Grid container spacing={4} mt={2}>
                      {dataForIntroductionFlow.map((item, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          key={index}
                          sx={{ marginBottom: "40px" }}
                        >
                          <Card
                            sx={{
                              backgroundColor: "#FFF",
                              borderRadius: 4,
                              // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                              transition: "box-shadow 0.3s",
                              // "&:hover": {
                              //   boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                              // },
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={item.imgSrc}
                              alt={item.imgSrc}
                            />
                            <CardContent>
                              <Stack
                                sx={{
                                  marginBottom: "18px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    background: "#FF6000",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "28px",
                                  }}
                                >
                                  {item.title}
                                </Typography>
                              </Stack>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#000000",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  lineHeight: "22px",
                                }}
                              >
                                {item.description}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Container>
              </Stack>

              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "24px", sm: "38px" },
                  lineHeight: { xs: "32px", sm: "53px" },
                  color: "#000000",
                }}
              >
                創業への想い
              </Typography>
              <Stack spacing={4}>
                <Stack
                  spacing={3}
                  direction={"row"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={User}
                    alt={"user"}
                    style={{ height: "70px", width: "70px" }}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", sm: "28px" },
                      color: "#FF6000",
                    }}
                  >
                    熊本 浩明
                  </Typography>
                </Stack>

                {content.map((item, index) => (
                  <Stack key={index} spacing={2}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: { xs: "24px", sm: "36px" },
                        color: "#000000",
                      }}
                    >
                      {item.title}
                    </Typography>
                    {item.paragraphs.map((paragraph, pIndex) => (
                      <Typography
                        key={pIndex}
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "16px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "28px" },
                          color: "#000000",
                        }}
                      >
                        {paragraph}
                      </Typography>
                    ))}
                  </Stack>
                ))}
              </Stack>

              <Stack spacing={4} mt={5} mb={5}>
                <Stack
                  spacing={3}
                  direction={"row"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={User}
                    alt={"user"}
                    style={{ height: "70px", width: "70px" }}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", sm: "28px" },
                      color: "#FF6000",
                    }}
                  >
                    ヴィカス クマール
                  </Typography>
                </Stack>

                {contentTwo.map((item, index) => (
                  <Stack key={index} spacing={2}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        fontWeight: 700,
                        lineHeight: { xs: "24px", sm: "36px" },
                        color: "#000000",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      ref={philosophyRef}
                      id="企業理念"
                      sx={{ height: "1px", visibility: "hidden" }}
                    />
                    {item.paragraphs.map((paragraph, pIndex) => (
                      <Typography
                        key={pIndex}
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "16px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "28px" },
                          color: "#000000",
                        }}
                      >
                        {paragraph}
                      </Typography>
                    ))}
                  </Stack>
                ))}
              </Stack>
              {/* </>
                  )} */}

              {/* {tab === "企業理念" && (
                    <> */}

              <Stack spacing={3}>
                <Stack spacing={3}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "24px", sm: "38px" },
                      lineHeight: { xs: "32px", sm: "53px" },
                      color: "#000000",
                    }}
                  >
                    ミッション
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", sm: "36px" },
                      color: "#000000",
                    }}
                  >
                    将来を見据えたグローバリゼーションと次世代を切り開くイノベーションを実現します
                  </Typography>

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "24px", sm: "28px" },
                      color: "#000000",
                    }}
                  >
                    グローバルビジネスと、テクノロジーに通じた専門家が、貴社のビジネスの成長をサポート致します。日本企業の成功の鍵を握るのは、デジタル人材を活用したグローバル化です。
                    私達は、海外のＩＴエンジニアとチームを組み、テクノロジーを活かした競争力の強い企業への進化を支援いたします。
                  </Typography>
                </Stack>

                <Stack spacing={3}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "24px", sm: "38px" },
                      lineHeight: { xs: "32px", sm: "53px" },
                      color: "#000000",
                    }}
                  >
                    バリュー
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", sm: "36px" },
                      color: "#000000",
                    }}
                  >
                    グローバルジャパンは、グローバルワンチームで高品質のサービスをコミットメント致します
                  </Typography>

                  <Stack>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "24px", sm: "28px" },
                        color: "#000000",
                      }}
                    >
                      弊社のスタッフは、海外ビジネスの経験も豊富なＩＴエンジニアや専門家です。日本人ばかりでなく、外国人の専門家も日本に常駐して、チームが一体となってきめ細かいグローバルでのご対応をさせていただきます。
                    </Typography>
                    <Stack sx={{ marginLeft: "20px" }}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "16px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "28px" },
                          color: "#000000",
                        }}
                      >
                        (※)2023年時点 出典：Jetro 地域分析レポート
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box
                ref={historyRef}
                id="沿革"
                sx={{ height: "1px", visibility: "hidden" }}
              />
              <Stack py={5}>
                <Divider />
              </Stack>
              {/* </>
                  )} */}
              {/* {tab === "沿革" && (
                    <> */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={3}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: "24px", sm: "38px" },
                        lineHeight: { xs: "32px", sm: "53px" },
                        color: "#000000",
                      }}
                    >
                      沿革{" "}
                    </Typography>
                    <Stack spacing={5} direction={{ xs: "column", sm: "row" }}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        2016年5月
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        株式会社グローバルジャパンネットワーク設立
                      </Typography>
                    </Stack>

                    <Stack spacing={5} direction={{ xs: "column", sm: "row" }}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        2021年6月
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        インド子会社設立（Global Japan Dx Lab Private limited）
                      </Typography>
                    </Stack>
                    <Stack spacing={5} direction={{ xs: "column", sm: "row" }}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        2023年7月
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "16px", sm: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "24px", sm: "56px" },
                          color: "#000000",
                        }}
                      >
                        Saas経営管理ツール("Insight Global")の販売開始
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              {/* </>
                  )} */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AboutUsCompany;
