import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import IconImg from "../../../assets/images/circleArrow.svg";
import Fullbgservice from "../../../assets/images/servicebgscreen1.svg";
import Fullwhitebg from "../../../assets/images/fullwhitebg.svg";
import HoverIcon from "../../../assets/images/hoverIcon.svg";
import ServiceCard from "../ServiceCard";
import { useNavigate } from "react-router-dom";
import { usePostStore } from "../../../store/postStore";
import Goldpartner from "../../../assets/images/goldpartner.png";
import Microsoft from "../../../assets/images/microsoftLogo.svg";
const ServiceSectionComponent = ({ isHide }) => {
  const navigate = useNavigate();

  const { services, getPosts } = usePostStore();

  useEffect(() => {
    getPosts("services");
  }, [getPosts]);
  return (
    <>
      <Box
        sx={{
          py: {
            xs: 6,
            sm: 8,
          },
          width: "100%",
          alignItems: "center",
          color: "#fff",
          backgroundRepeat: "no-repeat",
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: {
            xs: "none",
            sm:
              isHide === true ? `url(${Fullbgservice})` : `url(${Fullwhitebg})`,
          },
          minHeight: isHide === true ? "2230px" : "",
        }}
      >
        <Container>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
                lineHeight: "33px",
                marginBottom: 0,
              }}
            >
              Service
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: {
                  xs: "18px",
                  sm: "50px",
                },
                color: "#000000",
                lineHeight: { xs: '20px', md: "70px" },

              }}
            >
              サービス
            </Typography>
            <Typography
              // mt={6}
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 400,
                lineHeight: "36px",
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                color: "#000000",
                mt: {
                  xs: 0,
                  sm: 3,
                },
              }}
            >
              日本企業のグローバル化とDX化を促進し、競争力向上を支援しています。
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 400,
                lineHeight: "36px",
                fontSize: "20px",
                color: "#000000",
              }}
            >
              優れた海外IT人材の活用や海外オフショア開発、人材紹介・派遣、教育ならお任せください。
            </Typography>

            <hr
              style={{
                borderTop: "1px solid #EEEEEE",
                marginTop: "4em",
                marginBottom: "0px",
              }}
            />
            {services.map((service, index) => (
              <>
                <ServiceCard index={index} service={service} />
                <hr
                  style={{
                    borderTop: "1px solid #EEEEEE",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />
              </>
            ))}
            {isHide && (
              <Stack spacing={3} width={"100%"}>
                <Stack
                  py={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: { xs: 0, sm: 6 },
                      "&:hover": {
                        "& .hover-text": {
                          background: "#000",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          transform: "translateX(5px)",
                        },
                        "& .hover-icon": {
                          content: `url(${HoverIcon})`,
                        },
                      },
                    }}
                    onClick={() => navigate("/services")}
                  >
                    <Typography
                      variant="body1"
                      className="hover-text"
                      sx={{
                        fontWeight: 400,
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: "25px",
                        color: "#000",
                        transition: "color 0.3s ease, transform 0.3s ease",
                      }}
                    >
                      事業内容へ
                    </Typography>

                    <img
                      src={IconImg}
                      className="hover-icon"
                      style={{
                        paddingLeft: "1em",
                        width: "60px",
                        height: "60px",
                        transition: "transform 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.currentTarget.src = HoverIcon)}
                      onMouseLeave={(e) => (e.currentTarget.src = IconImg)}
                      alt=""
                    />
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: "#EFEFEF",
                    width: { xs: "100%", md: "1115px" },
                    height: { xs: "100%", md: "272px" },
                    borderRadius: 3,
                  }}
                >
                  <Stack
                    sx={{
                      // width: { xs: "100%", md: "1115px" },
                      height: { xs: "100%", md: "272px" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                    px={5}
                    py={5}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: "20px", sm: "38px" },
                        lineHeight: "53px",
                        color: "#000",
                      }}
                    >
                      私達はodoo・Microsoftの公認パートナーです
                    </Typography>
                    <Stack
                      direction={{ xs: "row", sm: "row" }}
                      spacing={{ xs: 2, sm: 6 }}
                      alignItems="center"
                    >
                      <Box
                        component="img"
                        src={Microsoft}
                        alt="Microsoft"
                        sx={{
                          width: { xs: "70px", md: "189px" },
                          height: { xs: "30px", md: "69px" },
                        }}
                      />
                      <Box
                        component="img"
                        src={Goldpartner}
                        alt="Gold Partner"
                        sx={{
                          width: { xs: "70px", md: "164px" },
                          height: { xs: "30px", md: "71px" },
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>)}
          </Box>

          {/* {isHide && (
            <>
              <Hidden mdDown>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: zoomLevel === 1 ? "21%" : "20%",
                    right: zoomLevel === 1 ? "2%" : "6%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    // backgroundColor: "rgba(0, 0, 0, 0.6)",
                    // padding: "8px 16px",
                    borderRadius: "4px",
                    fontWeight: "bold",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        "& .hover-text": {
                          background: "#000",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          transform: "translateX(5px)",
                        },
                        "& .hover-icon": {
                          content: `url(${HoverIcon})`,
                        },
                      },
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      onClick={() => navigate("/services")}
                    >
                      <Typography
                        variant="body1"
                        className="hover-text"
                        sx={{
                          fontWeight: 400,
                          fontSize: { xs: "14px", sm: "18px" },
                          lineHeight: "25px",
                          color: "#000",
                          transition: "color 0.3s ease, transform 0.3s ease",
                        }}
                      >
                        事業内容へ
                      </Typography>

                      <img
                        src={IconImg}
                        className="hover-icon"
                        style={{
                          paddingLeft: "1em",
                          width: "60px",
                          height: "60px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.src = HoverIcon)}
                        onMouseLeave={(e) => (e.currentTarget.src = IconImg)}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <MobilePartnerSection />
              </Hidden>
            </>
          )} */}
        </Container>
        {/* {isHide && (
        <>
          <Hidden mdDown>
            <PartnerSection />
          </Hidden>
          <Hidden mdUp>
            <MobilePartnerSection />
          </Hidden>
        </>
      )} */}
        {/* <Box
          sx={{
            position: "absolute",
            left: zoomLevel === 1 ? 180 : 220,
            bottom: zoomLevel === 1 ? 230 : 200,
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            gap: 1,
            padding: 1,
          }}
        >
          <img
            src={Microsoft}
            alt="Microsoft"
            style={{ width: "189px", height: "69px" }}
          />
          <img
            src={Goldpartner}
            alt="Gold Partner"
            style={{ width: "164px", height: "71px" }}
          />
        </Box> */}
      </Box>
    </>
  );
};

export default ServiceSectionComponent;
