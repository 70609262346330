import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const SpecializedDevelopment = ({ data, title, subTitle, clr }) => {
  return (
    <Stack backgroundColor={"#F8F8F8"} py={3}>
      <Container>
        <Stack py={6}>
          <Stack>
            <Stack>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "33px",
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "50px",
                  lineHeight: "70px",
                  color: "#000000",
                }}
              >
                {subTitle}
              </Typography>
            </Stack>
          </Stack>

          <Grid container spacing={4} mt={2}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 4,
                    height: "161px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.0)",
                    transition: "box-shadow 0.3s",
                    position: "relative",
                    "&:hover": {
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{
                            width: "99px",
                            height: "109px",
                            objectFit: "contain",
                          }}
                          src={item.imgSrc}
                          alt="Frame 1002"
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "36px",
                            color: clr ? "#FF6000" : "#000000",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#898989",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "25px",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
};

export default SpecializedDevelopment;
