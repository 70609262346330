import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import MenuIconImg from "../../assets/images/menu.svg";
import PopupBg from "../../assets/images/menuBg.svg";
import CustomButton from "../button/CustomButton";

const pages = [
  { name: "ホーム", link: "/" },
  { name: "特徴", link: "/features" },
  { name: "プロダクト", link: "/products/" },
  {
    name: "サービス",
    link: "/services",
    subPages: [
      { name: "システム開発支援", link: "/services/system-development" },
      { name: "コンサルティング", link: "/services/consulting" },
      { name: "Odooソリューション", link: "/services/odoo-solutions" },
      { name: "Microsoftソリューション", link: "/services/microsoft-solution" },
      { name: "人材派遣", link: "/services/engineering" },
    ],
  },
  { name: "実績", link: "/achievements" },
  {
    name: "会社情報",
    link: "/会社概要/",
    subPages: [
      { name: "ミッション", link: "/会社概要/" },
      { name: "会社概要", link: "/会社概要/" },
      { name: "経営メンバー", link: "/会社概要/" },
    ],
  },
  { name: "ブログ", link: "/category/all/" },
];

const mobilePages = [
  { name: "ホーム", link: "/" },
  { name: "特徴", link: "/features" },
  { name: "プロダクト", link: "/products/" },
  { name: "サービス", link: "/services" },
  { name: "実績", link: "/achievements" },
  { name: "会社情報", link: "/会社概要/" },
  { name: "ブログ", link: "/category/all/" },
  { name: "お問い合わせ", link: "/contact-us" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  
  // Log or use the decoded path
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const isActive = (link) => {
    const active = decodeURIComponent(location.pathname) === link;
    return active;
  };

  return (
    <AppBar
      position={isScrolled ? "fixed" : "static"}
      // position={"fixed"}
      sx={{
        backgroundColor: isScrolled ? "#fff" : "transparent",
        color: "#000",
        boxShadow: isScrolled ? "0 4px 10px -2px rgba(0, 0, 0, 0.2)" : "none",
        top: 0,
        width: "100%",
        transition: "all 0.5s ease",
        minHeight: isScrolled ? "60px" : "60px",
        zIndex: 1200,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            minHeight: { xs: isScrolled ? 56 : 56, sm: isScrolled ? 80 : 80 },
            paddingLeft: { xs: 0, sm: "2em" },
            paddingRight: { xs: 0, sm: "2em" },
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            sx={{
              height: {
                xs: isScrolled ? 40 : 56,
                sm: isScrolled ? 56 : "auto",
              },
              display: "flex",
              cursor: "pointer",
            }}
            alt="Logo"
            src={Logo}
            onClick={() => navigate("/")}
          />

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              // ml: "6em",
              flexGrow: 1,
            }}
          >
            {pages.map((page) => (
              <div
                key={page.name}
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "1em",
                }}
                onMouseEnter={(e) =>
                  page.subPages &&
                  (e.currentTarget.querySelector(".dropdown").style.display =
                    "block")
                }
                onMouseLeave={(e) =>
                  page.subPages &&
                  (e.currentTarget.querySelector(".dropdown").style.display =
                    "none")
                }
              >
                <Link to={page.link} style={{ textDecoration: "none" }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      fontSize: "16px",
                      fontWeight: 400,
                      color: isActive(page.link) ? "#FF6000" : "#000000",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#FF6000",
                        bottom: isScrolled ? "-20px" : "-20px",
                        left: 0,
                        transform: isActive(page.link)
                          ? "scaleX(1)"
                          : "scaleX(0)",
                        transformOrigin: "left",
                        transition: "transform 0.3s ease",
                      },
                      "&:hover::after": {
                        transform: "scaleX(1)",
                      },
                      "&:hover": {
                        color: "#FF6000",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                </Link>

                {page.subPages && (
                  <div
                    className="dropdown"
                    style={{
                      display: "none",
                      position: "absolute",
                      top: "85%",
                      left: 0,
                      backgroundColor: "white",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                      overflow: "hidden",
                      padding: "0.5em 0",
                      zIndex: 100,
                      width: "max-content",
                      // marginTop: 40,
                      color: isActive(page.link) ? "#FF6000" : "#000000",

                      // transform: isActive(page.link)
                      // ? "scaleX(1)"
                      // : "scaleX(0)",
                      transformOrigin: "left",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    {page.subPages.map((subPage) => (
                      <div
                        key={subPage.name}
                        onClick={() =>
                          navigate(subPage.link, {
                            state: { propName: subPage.name },
                          })
                        }
                        style={{
                          textDecoration: "none",
                          display: "block",
                          padding: "0.5em 1em",
                          color: "#000000",
                          cursor: "pointer",
                          transition:
                            "background-color 0.3s ease, color 0.3s ease",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#FF6000";
                          e.currentTarget.style.color = "#FFFFFF";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.color = "#000000";
                        }}
                      >
                        {subPage.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Box>

          <Box sx={{ flexGrow: { xs: 1, md: 0 } }} />

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Box
              component="img"
              src={MenuIconImg}
              alt="Menu"
              onClick={handleOpenNavMenu}
              sx={{
                cursor: "pointer",
                width: "40px",
                height: "auto",
              }}
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  minWidth: "100vw !important",
                  minHeight: "100vh !important",
                  top: "0px !important",
                  left: "0px !important",
                  backgroundImage: `url('${PopupBg}')`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <IconButton onClick={handleCloseNavMenu} sx={{ color: "#000" }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {mobilePages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.link}
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      fontFamily: "Noto Sans JP, sans-serif",
                      fontSize: "20px",
                      fontWeight: 400,
                      marginBottom: "1em",
                      paddingLeft: "1em",
                    }}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
              <Box
                sx={{
                  mt: 20,
                  textAlign: "left",
                  color: "#ff6000",
                  fontFamily: "Noto Sans JP, sans-serif",
                  lineHeight: "28px",
                  fontSize: "20px",
                  fontWeight: 400,
                  ml: 4,
                }}
              >
                03-6264-0803
              </Box>

              <Box
                sx={{
                  mt: 2,
                  textAlign: "left",
                  color: "#ff6000",
                  fontFamily: "Noto Sans JP, sans-serif",
                  lineHeight: "28px",
                  fontSize: "20px",
                  fontWeight: 400,
                  ml: 4,
                }}
              >
                info@g-japan.com
              </Box>
            </Menu>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            onClick={() => navigate("/お問い合わせ-2/")}
          >
            <CustomButton text="お問い合わせ" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
