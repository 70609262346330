import React from "react";
import { Button, Box } from "@mui/material";

const SegmentedButton = ({ selectedTitle, setSelectedTitle }) => {

  const buttonStyles = (isSelected) => ({
    color: isSelected ? "#fff" : "#898989",
    background: isSelected
      ? "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)"
      : "#f2f2f2", // Unselected button background
    fontWeight: isSelected ? 700 : 500,
    fontFamily: "Noto Sans JP, sans-serif",
    fontSize: "16px",
    padding: "10px 40px",
    border: "none",
    borderRadius: isSelected ? "30px" : "30px", // Fully rounded pill shape
    transition: "background 0.3s ease, color 0.3s ease",
    // boxShadow: isSelected ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none", // Light shadow for the selected button
    // "&:hover": {
    //   background: isSelected
    //     ? "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)"
    //     : "#e0e0e0", // Hover effect for unselected button
    // },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "max-content",
        padding: "5px",
        backgroundColor: "#f2f2f2",
        borderRadius: "30px",
      }}
    >
      <Button
        sx={buttonStyles(selectedTitle === "サービス")}
        onClick={() => setSelectedTitle("サービス")}
      >
        サービス
      </Button>
      <Button
        sx={buttonStyles(selectedTitle === "カテゴリ")}
        onClick={() => setSelectedTitle("カテゴリ")}
      >
        カテゴリ
      </Button>
    </Box>
  );
};

export default SegmentedButton;
