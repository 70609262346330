import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1010 from "../../assets/images/Frame1010.svg";
import Frame1011 from "../../assets/images/Frame1011.svg";
import Frame1013 from "../../assets/images/Frame1013.svg";
import Frame1014 from "../../assets/images/Frame1014.svg";
import GradientHeader from "../../components/product/GradientHeader";
import AccordionScreen from "../../components/system-development/AccordionScreen";
import FlexibleDevelopmentSystem from "../../components/system-development/FlexibleDevelopmentSystem";
import Introduction from "../../components/system-development/Introduction";
import SpecializedDevelopment from "../../components/system-development/SpecializedDevelopment";
import StartOfDevelopment from "../../components/system-development/StartOfDevelopment";
import SystemStrengthSection from "../../components/system-development/SystemStrength";
import TechnologyAndSkills from "../../components/system-development/TechnologyAndSkills";
import { usePostStore } from "../../store/postStore";

const title = "Case Study";
const subTitle = "実績紹介";
const navi = "/achievements";
const headtitle = "System Development Support";
const headsubTitle = "システム開発支援";
const headsubdec = "サービス > システム開発支援";
const dataForSystem = [
  {
    title: "円滑なコミュニケーションをサポート",
    description:
      "海外オフショア開発の日本人専門家が貴社の窓口となり、円滑なコミュニケーションをサポート。多くの実績を持つプロジェクト管理ツールを最大限利用。",
    imgSrc: Frame1010,
  },
  {
    title: "幅広い案件対応の最適なチーム",
    description:
      "システム開発、アプリ開発、サイト制作など幅広い領域において、最先端のスキルと豊富な経験を持つメンバーでチームを構成。",
    imgSrc: Frame1011,
  },
  {
    title: "高い技術＆リーズナブルプライス",
    description:
      "自社開発と比べ、大幅なコスト削減が可能。フィリピンやベトナムなど、他の海外オフショア開発と比較した場合、技術力に比べ、圧倒的なコストパフォーマンスを実現。",
    imgSrc: Frame1013,
  },
  {
    title: "経験豊富なメンバーが緊密に連携",
    description:
      "グローバルビジネスに精通した専門家がチームに参加します。両国間の緊密なコミュニケーションのもと、プロジェクトを効率的に遂行。",
    imgSrc: Frame1011,
  },
];
const systemTitle = "Specialized development area";
const systemsubTitle = "得意な開発分野";
const dataForSystemArea = [
  {
    title: "オフショア開発ソリューション",
    description:
      "海外の優れたIT人材を活用し、効率的で高品質なソフトウェア開発を提供します。",
    imgSrc: Frame1014,
  },
  {
    title: "アプリケーション開発",
    description:
      "ユーザーのニーズに応じたソフトウェアを設計・開発し、ビジネス目標達成を支援。",
    imgSrc: Frame1014,
  },
  {
    title: "ウェブシステム開発",
    description:
      "インターネットを介して利用可能なウェブアプリケーションやサイトを構築。",
    imgSrc: Frame1014,
  },
  {
    title: "モバイルアプリ開発",
    description:
      "スマートフォンやタブレット向けのアプリケーションを開発・提供。",
    imgSrc: Frame1014,
  },
  {
    title: "VR/AR/3D",
    description: "仮想現実や拡張現実、3D技術を活用した体験を提供。",
    imgSrc: Frame1014,
  },
  {
    title: "データ分析・AI",
    description:
      "ビッグデータを解析し、予測や意思決定を支援する人工知能の開発。",
    imgSrc: Frame1014,
  },
  {
    title: "インフラ",
    description:
      "ハードウェアやソフトウェアを組み合わせて、システムの基盤を構築・管理。",
    imgSrc: Frame1014,
  },
  {
    title: "クラウド",
    description:
      "インターネット上のクラウドプラットフォームを構築・提供し、サービスを展開。",
    imgSrc: Frame1014,
  },
  {
    title: "ネットワーク",
    description: "コンピューター同士を接続するための通信インフラを設計・構築。",
    imgSrc: Frame1014,
  },
  {
    title: "セキュリティ(脆弱性診断)",
    description:
      "システムやアプリの脆弱性を分析し、セキュリティを強化するソリューションを開発。",
    imgSrc: Frame1014,
  },
];
const systemTitleArea = "Strengths of system development support";
const systemsubTitleArea = "システム開発支援の強み";
const dataForIntro = [
  {
    no: "01",
    title: "お問い合わせ",
    description:
      "まずは資料請求、お問合せをいただき、ご希望の業務内容を教えていただきます。案件内容、スケジュール、費用感、チーム構成等、案件概要をヒヤリングし、貴社にとって最適なプランをご提案いたします。ご提案内容・お見積りにご納得いただけるまで、複数回お打ち合わせを行い、ご提案をさせて頂きます。",
  },
  {
    no: "02",
    title: "インドとのＷeb会議",
    description:
      "チーム編成が決まりましたら、日本側、インド側のスタッフを含めてWeb会議を設定いたします。Ｗeb会議で、日本側、インド側の役割分担、コミュニケーション体制、セキュリティ対策、スタッフの日本語レベル、現地スタッフの働く環境等の様々な状況をご説明いたします。 ",
  },
  {
    no: "03",
    title: "ご契約",
    description:
      "ご契約は、グローバルジャパンネットワークとのご契約となります。ご契約はすべて日本語で行いますので、ご安心ください。内容に問題が無ければご契約の締結となります。 ",
  },
  {
    no: "04",
    title: "開発立ち上げ前支援",
    description:
      "開発立ち上げ前に、開発資料をいただき日本側チーム、現地チーム交えて、開発がスムーズに立ち上がるよう調整を行います。 ",
  },
  {
    no: "05",
    title: "開発スタート",
    description:
      "プロジェクトの定期ミーティングを行い、進捗状況を適切にモニタリングを致します",
  },
];
const systemTitleFlow = "Flow until the start of development";
const systemsubTitleFlow = "開発スタートまでの流れ";
const dataForAccordion = [
  {
    title: "インドオフショア開発のメリット、デメリット、注意点は何ですか？",
    answer:"メリットは、優秀なエンジニアによるコスト効率の良い開発と、豊富な人材を効率的に活用したプロジェクト管理が可能なことです。デメリットとしては、時差や文化の違いに起因するコミュニケーション課題が挙げられます。注意点としては、適切なプロジェクト管理と明確な要件定義が重要です。"
  },
  {
    title: "最低契約期間はありますか？",
    answer:'最低契約期間は、プロジェクトの性質やスコープに応じて柔軟に対応しています。通常、短期プロジェクトであれば3か月程度が目安ですが、長期契約も可能です。詳細は相談に応じます。'
  },
  {
    title: "チームの準備にかかる時間はどうですか？",
    answer:"チームの準備には、要件の確認やスキルマッチングに約1～2週間かかります。お客様のプロジェクト要件に合わせて迅速に最適なエンジニアを選定し、スムーズなスタートを目指します。"
  },
  {
    title: "何人まで参加できますか？",
    answer:"プロジェクトの規模やニーズに応じて柔軟に対応します。小規模プロジェクトなら2～3名、大規模なものでは10名以上のチームを編成することが可能です。規模に応じて最適なチームを提案します。"

  },
  {
    title: "日本語のコミュニケーションは可能ですか？",
    answer:"日本語対応可能なエンジニアやプロジェクトマネージャーが在籍しています。基本的なビジネスコミュニケーションは日本語で対応可能ですが、技術的な詳細は英語でのやり取りが必要な場合もあります。"

  },
  {
    title: "エンジニアの技術レベルを教えてください",
    answer:"当社のエンジニアは、最新の技術に精通し、実務経験豊富です。特にWeb開発、クラウドソリューション、モバイルアプリ開発に強みがあります。また、AzureやAWS、AI、機械学習など先端技術も扱うことが可能です。"

  },
  {
    title: "インド側のチームメンバーの離職率はどうですか？",
    answer:"当社の離職率は業界平均よりも低く、安定したチーム運営を実現しています。これは、キャリア成長や報酬、職場環境に重点を置いているためです。長期プロジェクトでも安定したチームで対応します。"

  },
  {
    title: "QC（テストエンジニア）を割り当てることはできますか？",
    answer:"はい、QC（テストエンジニア）をプロジェクトの一環として割り当てることが可能です。開発の品質保証を重視しており、専任のテストエンジニアが徹底した品質チェックを行います。"

  },
  {
    title: "インドとの時差とは何時間でしょうか？",
    answer:"日本とインドの時差は通常3時間30分です。これにより、両国の稼働時間に少しの重複があり、効率的なコミュニケーションが可能です。これを活かして迅速な対応が行えます。"

  },
  {
    title: "事前にチームメンバーにインタビューできますか？",
    answer:"はい、プロジェクト開始前にお客様がチームメンバーにインタビューすることが可能です。これにより、お客様の要件に最適なエンジニアを選定し、信頼関係を築くことができます。"

  },
  {
    title:
      "インド側のエンジニアが短期間でも良いので日本に来ることができますか？",
    answer:"短期間の出張対応も可能です。ビザの取得や渡航準備に少し時間がかかる場合がありますが、必要に応じて現地での対応も含めたサポートを行います。"

  },
];
const titleAccordion = "よくあるご質問";
const SystemDevelopmentPage = () => {

  const { casestudies, getPosts } = usePostStore();

  useEffect(() => {
    getPosts('casestudies');
  }, [getPosts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>システム開発支援 | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <SystemStrengthSection
        data={dataForSystem}
        title={systemTitleArea}
        subTitle={systemsubTitleArea}
      />
      <SpecializedDevelopment
        data={dataForSystemArea}
        title={systemTitle}
        subTitle={systemsubTitle}
      />
      <TechnologyAndSkills />
      <FlexibleDevelopmentSystem />
      <StartOfDevelopment
        data={dataForIntro}
        title={systemTitleFlow}
        subTitle={systemsubTitleFlow}
      />
      <Introduction
        data={casestudies}
        isHide={true}
        title={title}
        subTitle={subTitle}
        navi={navi}
      />
      <AccordionScreen data={dataForAccordion} title={titleAccordion} />
    </>
  );
};
export default SystemDevelopmentPage;
