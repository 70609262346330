import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1006 from "../../assets/images/Frame1006.svg";
import Frame1007 from "../../assets/images/Frame1007.svg";
import Frame1008 from "../../assets/images/Frame1008.svg";
import Frame1014 from "../../assets/images/Frame1014.svg";
import Needs1 from "../../assets/images/needs1.svg";
import Needs2 from "../../assets/images/needs2.svg";
import Needs3 from "../../assets/images/needs3.svg";
import Needs4 from "../../assets/images/needs4.svg";
import Office1 from "../../assets/images/office1.svg";
import Office10 from "../../assets/images/office10.svg";
import Office2 from "../../assets/images/office2.svg";
import Office3 from "../../assets/images/office3.svg";
import Office4 from "../../assets/images/office4.svg";
import Office5 from "../../assets/images/office5.svg";
import Office6 from "../../assets/images/office6.svg";
import Office7 from "../../assets/images/office7.svg";
import Office8 from "../../assets/images/office8.svg";
import Office9 from "../../assets/images/office9.svg";
import Rounddesign from "../../assets/images/rounddesign.svg";
import CarouselForEngineering from "../../components/engineering/CarouselForEngineering";
import MeetYourNeeds from "../../components/engineering/MeetYourNeeds";
import GradientHeader from "../../components/product/GradientHeader";
import InsightGlobal from "../../components/product/InsightGlobal";
import AccordionScreen from "../../components/system-development/AccordionScreen";
import SpecializedDevelopment from "../../components/system-development/SpecializedDevelopment";
import StartOfDevelopment from "../../components/system-development/StartOfDevelopment";
import SystemStrengthSection from "../../components/system-development/SystemStrength";

const headtitle = "Temporary staffing";
const headsubTitle = "人材派遣";
const headsubdec = " サービス > 人材派遣";

const dataForService = [
  {
    title: "インドでの現地ネットワーク",
    description:
      "幅広いネットワークから、現地の優秀なＩＴ人材をご紹介することが可能です。プロフェッショナルが日本に常駐し、グローバルネットワークを活用して情報提供させていただきます。",
    imgSrc: Office3,
  },
  {
    title: "豊富な人材データベース",
    description:
      "システム開発、アプリ開発、サイト制作など幅広い領域において優秀なＩＴエンジニアの人材を揃えており、貴社のご要望に合う人材を迅速にご紹介することが可能です。",
    imgSrc: Office2,
  },
  {
    title: "プロフェッショナルが日本に常駐",
    description:
      "弊社のスタッフは、ビジネスの経験も豊富な弁護士や税理士、公認会計士です。インド人スタッフが日本に常駐し、貴社をサポートいたします。",
    imgSrc: Office4,
  },
  {
    title: "採用からアフターフォローまで 手厚くワンストップサポート",
    description:
      "ご紹介した人材が長く貴社で活躍できるように、アフターフォローも行います。ご紹介する人材が気持ちよく働けるようにワンストップでサポートいたします。",
    imgSrc: Office1,
  },
];

const serviceTitle = "Strengths of global IT staffing/recruitment";
const ServicesubTitle = "グローバルIT人材派遣・人材紹介の強み";

const dataForService2 = [
  {
    title: "優秀なIT技術者の手で課題解決",
    description:
      "各分野で高い専門性を有した技術者の参画、アウトプットにより、効率的にプロジェクトを進行させ、課題解決に貢献していきます",
    imgSrc: Office8,
  },
  {
    title: "雇用リスクの軽減",
    description:
      "SESを活用することで雇用による様々なリスクを解消でき、プロジェクト単位の参画から柔軟な運用体制の構築が可能です",
    imgSrc: Office10,
  },
  {
    title: "機会損失＆経費を削減",
    description:
      "自社で人材採用および教育段階を踏まずに、必要なスキルを有した技術者を確保できることから機会損失の軽減、経費削減が期待できます",
    imgSrc: Office9,
  },
  {
    title: "より精度が高い人材への追求",
    description:
      "エンジニアが各プロジェクトでパフォーマンスを発揮する為には、開発要件を満たしたスキル面だけではなく、個人と開発現場との志向性のマッチングも必要であると考えています。一つ一つの本質的なマッチングからノウハウを蓄積していきます。",
    imgSrc: Office7,
  },
  {
    title: "専任コンサルタントが一貫したマネージメント",
    description:
      "IT業界に精通した専属コンサルタントがマッチングから参画後のマネージメントを通じて丁寧に対応していきます。特にマッチングにおいては、市場価値を理解した上で、エンジニアと企業双方の参画ニーズを捉えた最適なマッチングをお届けします。",
    imgSrc: Office6,
  },
  {
    title: "エンド企業とのマッチングにこだわりを",
    description:
      "当社では、優良なエンド企業への営業活動、関係性づくりに注力しています。それらの密な現場訪問から、より満足度の高い案件への参画支援、エンジニアと企業双方にとって魅力的なプロジェクトを実現しています。　",
    imgSrc: Office5,
  },
];

const serviceTitle2 = "Advantages of using SES";
const ServicesubTitle2 = "SESを利用するメリット";

const dataForNeeds = [
  {
    title: "ラボ開発",
    description:
      "日本語レベル、ＩＴ技術レベル、専門領域について、貴社のニーズに合わせたインド人ＩＴエンジニアを派遣いたします。弊社がインド人スタッフを採用、契約をし、貴社に派遣します。スタッフへの給与は弊社から支払うため、貴社は当社へのお支払いのみで、社会保険や有給休暇、交通費等も必要ありません。",
    imgSrc: Needs2,
  },
  {
    title: "紹介予定派遣",
    description:
      "社員採用を前提として一定期間派遣スタッフを受け入れ、 企業とスタッフ双方の希望が一致すれば、直接雇用に切り替えるシステムです。派遣期間が企業とスタッフ双方の見極め期間となるため、雇用のミスマッチを防ぎ、効率的な人材の確保が可能となります。",
    imgSrc: Needs1,
  },
];

const needsTitle =
  "Dispatch talented personnel as quickly as possible to meet your needs";
const needsSubTitle = "ニーズに合わせて最速で優秀な人材を派遣";

const dataForNeeds2 = [
  {
    title: "システムエンジニアリングサービス（SES）",
    description:
      "働いた時間に対して報酬をもらう完成義務を負わない 指揮命令権はSES企業にあるグローバルジャパンネットワークのSESは、企業と経験豊富なITエンジニアを効果的に結びつけ、プロジェクト単位の技術支援や開発支援を提供します。ソフトウェアやシステムの開発から保守運用まで幅広く対応し、課題解決と業務負荷の軽減、より効率的で安定した運営を支援します。",
    imgSrc: Needs3,
  },
  {
    title: "派遣契約の特徴",
    description:
      "働いた時間に対して報酬をもらう完成義務を負わない 指揮命令権は派遣先企業にあるSES（準委任契約）とは異なり、派遣契約をグローバルジャパンネットワークと締結していただき、お客様が指揮命令権を行使することで、現場での業務遂行がスムーズになります。",
    imgSrc: Needs4,
  },
];

const needsTitle2 = "Maximize cost performance with flexible contract formats";
const needsSubTitle2 = "柔軟な契約形態でコストパフォー マンスを最大化";

const dataForSystemOdoo = [
  {
    title: "Front End Engineer",
    description: "フロントエンド エンジニア",
    imgSrc: Frame1014,
  },

  {
    title: "Back End Engineer",
    description: "バックエンド エンジニア",
    imgSrc: Frame1014,
  },
  {
    title: "Network Engineer",
    description: "ネットワーク エンジニア",
    imgSrc: Frame1014,
  },
  {
    title: "Bridge Engineer",
    description: "ブリッジ エンジニア",
    imgSrc: Frame1014,
  },
  {
    title: "Data Scientist",
    description: "データ サイエンティスト",
    imgSrc: Frame1014,
  },
  {
    title: "QA & Test Engineer",
    description: "テスト エンジニア",
    imgSrc: Frame1014,
  },
  {
    title: "SAP Consultant SAP",
    description: "コンサルタント",
    imgSrc: Frame1014,
  },
];

const systemTitleOdoo =
  "Dispatch experts with skills in a wide range of fields";
const systemsubTitleOdoo = "幅広い領域のスキルを持ったエキスパートを派遣";

const dataForIntro = [
  {
    no: "01",
    title: "お問い合わせ",
    description:
      "まずは資料請求、お問合せをいただき、ご希望の業務内容を教えていただきます。案件内容、スケジュール、費用感、チーム構成等、案件概要をヒヤリングし、貴社にとって最適なプランをご提案いたします。ご提案内容・お見積りにご納得いただけるまで、複数回お打ち合わせを行い、ご提案をさせて頂きます。",
  },
  {
    no: "02",
    title: "インドとのＷeb会議",
    description:
      "チーム編成が決まりましたら、日本側、インド側のスタッフを含めてWeb会議を設定いたします。Ｗeb会議で、日本側、インド側の役割分担、コミュニケーション体制、セキュリティ対策、スタッフの日本語レベル、現地スタッフの働く環境等の様々な状況をご説明いたします。 ",
  },

  {
    no: "03",
    title: "ご契約",
    description:
      "ご契約は、グローバルジャパンネットワークとのご契約となります。ご契約はすべて日本語で行いますので、ご安心ください。内容に問題が無ければご契約の締結となります。a ",
  },

  {
    no: "04",
    title: "開発立ち上げ前支援",
    description:
      "開発立ち上げ前に、開発資料をいただき日本側チーム、現地チーム交えて、開発がスムーズに立ち上がるよう調整を行います。 ",
  },

  {
    no: "05",
    title: "開発スタート",
    description:
      "プロジェクトの定期ミーティングを行い、進捗状況を適切にモニタリングを致します",
  },
];

const systemTitleFlow = "開発スタートまでの流れ";
const systemsubTitleFlow = "開発スタートまでの流れ";

const dataForAccordion = [
  {
    title: "インド人エンジニアの技術レベルや専門分野はどのようなものですか？",
    answer:'インドのエンジニアは最新の技術に精通し、Web開発、モバイルアプリ、クラウドソリューションなど幅広い分野で豊富な経験を持っています。'
  },
  {
    title: "エンジニアを派遣するまでにどのくらいの時間がかかりますか？",
    answer:'プロジェクトの要件や技術スキルのマッチングにより異なりますが、通常は1〜2週間で派遣が可能です。'

  },
  {
    title: "日本語対応のエンジニアはいますか？",
    answer:'はい、日本語対応可能なエンジニアやプロジェクトマネージャーもおりますが、主に英語でのコミュニケーションとなることが多いです。'

  },
  {
    title: "派遣されるエンジニアの契約期間はどのくらいですか？",
    answer:'短期のプロジェクトから長期契約まで対応可能で、クライアントのニーズに合わせて柔軟に契約期間を設定できます。'

  },
  {
    title: "インド人エンジニアの稼働時間や勤務形態はどのようになりますか？",
    answer:'インドと日本の時差は3時間30分ですが、クライアントの希望に応じて、重複する稼働時間やリモートワークの調整が可能です。'

  },
];

const titleAccordion = "よくあるご質問";

const dataForCard = [
  {
    title: "人材派遣",
    description:
      "ラボ形式で貴社に専属のITエンジニアをインド側でアサインし、長期間に渡りノウハウを蓄積しながら、低コストで運用。貴社専属のメンバーとなりますので、貴社の指揮命令により業務を実施します。",
    imgSrc: Frame1006,
  },

  {
    title: "紹介予定派遣",
    description:
      "将来的に正社員での採用を検討している際の、インド人エンジニアの派遣となります。実際に業務を行っていくなかで、しっかりと見極めていただき、採用する際のミスマッチを防止することが可能になります。",
    imgSrc: Frame1007,
  },
  {
    title: "ビザ・コンプライアンス支援",
    description:
      "日本企業におけるインド人ITエンジニアの人事・労務対応を一括してサポートします。就労ビザの取得や税金手続き、社会保険などの法令遵守業務を日本・インドの法律専門家が担当します。",
    imgSrc: Frame1008,
    roundDesignSrc: Rounddesign,
  },
];

const cardTitle = "Service";
const cardSubTitle = "サービス内容";

const Engineering = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>人材派遣 | Global Japan Network</title>
      </Helmet>

      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />

      <InsightGlobal
        data={dataForCard}
        title={cardTitle}
        subTitle={cardSubTitle}
        clr={true}
      />

      <SystemStrengthSection
        data={dataForService}
        title={serviceTitle}
        subTitle={ServicesubTitle}
      />

      <MeetYourNeeds
        data={dataForNeeds}
        title={needsTitle}
        subTitle={needsSubTitle}
      />

      <SpecializedDevelopment
        data={dataForSystemOdoo}
        title={systemTitleOdoo}
        subTitle={systemsubTitleOdoo}
        clr={true}
      />

      <MeetYourNeeds
        data={dataForNeeds2}
        title={needsTitle2}
        subTitle={needsSubTitle2}
      />

      <SystemStrengthSection
        data={dataForService2}
        title={serviceTitle2}
        subTitle={ServicesubTitle2}
      />

      <CarouselForEngineering />
      <StartOfDevelopment
        data={dataForIntro}
        title={systemTitleFlow}
        subTitle={systemsubTitleFlow}
      />
      <AccordionScreen data={dataForAccordion} title={titleAccordion} />
    </>
  );
};

export default Engineering;
