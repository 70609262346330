import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import StyledSwitchButton from "../../common/button/StyledSwitchButton";
import PaginationComponent from "../../common/pagination";
import CustomNewsButton from "../../components/News/CustomNewsButton";
import GradientHeader from "../../components/product/GradientHeader";
import { usePostStore } from "../../store/postStore";
import Circle from "../../assets/images/blog/circle.svg";
import AcheivementCard from "../../common/Achievement/Card";

const headtitle = "Achievements";
const headsubTitle = "実績";
const headsubdec = "実績";

const AcheivementsPage = () => {
  const { achievementsLoading, achievements, getAchievements, categories, getCategories } = usePostStore();

  const [selectedTitle, setSelectedTitle] = useState("サービス");
  const [selectedButton, setSelectedButton] = useState("すべて");
  const [buttonLabels, setButtonLabels] = useState(["すべて", "システム開発", "グローバル支援管理ツール", "グローバルIT人材・人材紹介"]);
  const [slug, setSlug] = useState("achievementservice");

  const [currentPage, setCurrentPage] = useState(1);
  const achievementsPerPage = 12;

  const totalPages = Math.ceil(achievements.length / achievementsPerPage);
  const indexOfLastBlog = currentPage * achievementsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - achievementsPerPage;
  const currentPosts = achievements.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleButtonClick = (label) => {
    setSelectedButton(label);
  };

  useEffect(() => {
    setSelectedButton("すべて");
    if (selectedTitle === "サービス") {
      setSlug("achievementservice");
      getCategories("achievementservice")
    } else {
      setSlug("category");
      getCategories("category")
    }
  }, [selectedTitle, getCategories]);

  useEffect(() => {
    if (selectedTitle === "サービス") {
      const category = categories.find(cat => cat.name === selectedButton);
      setSlug(category ? category.slug : "achievementservice")
    } else {
      const category = categories.find(cat => cat.name === selectedButton);
      setSlug(category ? category.slug : "category")
    }
  }, [selectedButton, selectedTitle, categories]);

  useEffect(() => {
    setButtonLabels(["すべて", ...categories.map(category => category.name)]);
  }, [categories]);

  useEffect(() => {
    getAchievements(slug);
  }, [slug, getAchievements]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>実績 | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      {/* <Box sx={{ background: "#fff7f2" }}>
        <Container>
          <Box sx={{ py: 9 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background:
                  "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
                fontFamily: "Noto Sans JP, sans-serif",
              }}
            >
              Most read articles{" "}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: {
                  xs: "18px",
                  sm: "50px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
              }}
            >
              よく読まれている記事
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
                fontFamily: "Noto Sans JP, sans-serif",
                mt: {
                  xs: 3,
                  sm: 6,
                },
                color: "#000000",
              }}
            >
              グローバルジャパンネットワークはグローバルビジネスとテクノロジーに
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
                mb: 5,
                fontFamily: "Noto Sans JP, sans-serif",
                color: "#000000",
              }}
            >
              精通した専門家が、日本企業の成功をサポートします。
            </Typography>
            <BlogCarouselSlider />
          </Box>
        </Container>
      </Box> */}

      <Box sx={{ background: "#F8F8F8" }}>
        <Container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              gap: "16px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              py: 6,
            }}
          >
            <StyledSwitchButton
              selectedTitle={selectedTitle}
              setSelectedTitle={setSelectedTitle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              gap: "16px",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            {buttonLabels.map((label) => (
              <CustomNewsButton
                key={label}
                label={label}
                selectedButton={selectedButton}
                onClick={handleButtonClick}
              />
            ))}
          </Grid>
          <Box sx={{ position: "relative" }}>
            <Box
              component="img"
              src={Circle}
              alt="Background design"
              sx={{
                // height: { xs: 20, sm: 24 },
                ml: 3,
                position: "absolute",
                height: "492px",
                width: "492px",
                top: "3em",
                right: "-15em",
                zIndex: "-1",
              }}
            />
            <Stack pt={0} sx={{ position: "relative" }}>
              <Grid container spacing={4} mt={6}>
                {achievementsLoading ? (
                  // Show 3 placeholder cards while loading
                  [...Array(3)].map((_, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={`placeholder-${index}`}
                      sx={{ marginBottom: "40px" }}
                    >
                      <Card
                        sx={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: 4,
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                          height: "371px",
                          transition: "box-shadow 0.3s",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        <AcheivementCard slug={slug} post={_} categories={categories} achievementsLoading={achievementsLoading} />
                      </Card>
                    </Grid>
                  ))
                ) : currentPosts.length > 0 ? (
                  currentPosts.map((post, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={post.id} // Use post.id for a more unique key
                      sx={{ marginBottom: "40px" }}
                    >
                      <Card
                        sx={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: 4,
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                          height: "371px",
                          transition: "box-shadow 0.3s",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        <AcheivementCard slug={slug} post={post} categories={categories} achievementsLoading={achievementsLoading} />
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography></Typography>
                  </Grid>
                )}

                {/* {currentPosts.map((post, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    sx={{ marginBottom: "40px" }}
                  >
                    <Card
                      sx={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: 4,
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        height: "371px",
                        transition: "box-shadow 0.3s",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <AcheivementCard slug={slug} post={post} categories={categories} />
                    </Card>
                  </Grid>))} */}
              </Grid>
            </Stack>
          </Box>
          <PaginationComponent
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Container>
      </Box>
    </>
  );
};

export default AcheivementsPage;
