import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Arrowforward from "../../assets/images/arrowforward.svg";
import Otherhouses from "../../assets/images/otherhouses.svg";
import CarouselWithCards from "../../common/Post/CarouselWithCards";
import Detail from "../../common/Post/Detail";
import { usePostStore } from "../../store/postStore";



const PostDetail = () => {

    const location = useLocation();

    const { propName } = location.state || {};
    const navigate = useNavigate();
    const { id } = useParams();
    const { loading, achievementsLoading, post, getPostDetail, category, posts, getPosts, achievements, getAchievements } = usePostStore();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getPostDetail(id);
    }, [id, getPostDetail]);

    useEffect(() => {
        if (propName === "achievements") {
            getAchievements('achievementservice');
        } else {
            getPosts('popular');
        }
    }, [propName, getAchievements, getPosts]);

    return (
        <>
            <Helmet>
                <title>{`${post?.title?.rendered || 'ブログ'} | Global Japan Network`}</title>
            </Helmet>
            <Box sx={{ background: "#f0f0f0" }}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-start"
                    p={4}
                >
                    <img
                        style={{ width: "18px", height: "18px", cursor: "pointer" }}
                        src={Otherhouses}
                        alt="Other houses"
                        onClick={() => navigate('/')}
                    />
                    <img
                        style={{ width: "15px", height: "15px" }}
                        src={Arrowforward}
                        alt="Arrow forward"
                    />
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 400,
                            fontSize: { xs: "12px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "19px" },
                            color: "#898989",
                            display: "flex",
                            alignItems: "center",
                            margin: 0,
                            cursor: 'pointer'
                        }}
                        onClick={() => { propName === "achievements" ? navigate('/achievements') : navigate('/category/all/') }}

                    >
                        {propName === "achievements" ? "実績" : "ブログ"}
                    </Typography>
                    <img
                        style={{ width: "15px", height: "15px" }}
                        src={Arrowforward}
                        alt="Arrow forward"
                    />
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 400,
                            fontSize: { xs: "12px", sm: "14px" },
                            lineHeight: { xs: "16px", sm: "19px" },
                            color: "#898989",
                            display: "flex",
                            alignItems: "center",
                            margin: 0,
                        }}
                    >
                        {post?.title?.rendered}
                    </Typography>
                </Stack>
                <Detail post={post} category={category} />
                <Typography
                    mt={5}
                    align="center"
                    sx={{
                        fontFamily: 'Noto Sans JP, sans-serif',
                        fontSize: '38px',
                        fontWeight: 700,
                        lineHeight: '53.2px',
                        textAlign: 'center',
                    }}>よく読まれている記事</Typography>
                <Container >
                    {propName === "achievements" ?
                        <CarouselWithCards posts={achievements} slug={"achievementservice"} loading={achievementsLoading} /> :
                        <CarouselWithCards slug={'popular'} posts={posts} loading={loading} />}
                </Container>
            </Box>
        </>
    )
};

export default PostDetail;
