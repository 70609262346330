import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../../../common/button/CustomButton";
import { usePostStore } from "../../../store/postStore";
import { getFeaturedMedia } from "../../../utils/postUtils";

const ServiceUsage = () => {

  const { examples, getPosts } = usePostStore();

  useEffect(() => {
    getPosts("examples");
  }, [getPosts]); 
  return (
    <Stack backgroundColor={"#F8F8F8"}>
      <Container>
        <Stack py={6}>
          <Stack>
            <Stack>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "33px",
                }}
              >
                Wide range of service usage examples
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "50px",
                  lineHeight: "70px",
                  color: "#000000",
                }}
              >
                幅広いサービス活用事例
              </Typography>
            </Stack>
          </Stack>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                Insight Global
                は、事業内容や課題に合わせて、
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                多彩なカスタマイズが可能です。
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4} mt={6}>
            {examples.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: 4,
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.0)",
                    transition: "box-shadow 0.3s",
                    position: "relative",
                    "&:hover": {
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={3}>
                        <img
                          src={getFeaturedMedia(item)}
                          alt="Frame 1002"
                          style={{ width: "99px", height: "75px", zIndex: 1 }}
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>

                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "28px",
                          }}
                        >
                          {item?.title?.rendered}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Stack
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              (window.location.href = "https://insightglobal.jp/case-study")
            }
          >
            <CustomButton text="詳細を見る" />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ServiceUsage;
