import {
  Box,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import InfoIcon from "../../assets/images/infoIcon.svg";
import CustomButton from "../../common/button/CustomButton";
import { BASE_URL } from "../../constants/portConstants";
import { notify } from "../../utils/handler";
const InquiryForm = () => {
  const [error, setError] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  const handleSubmit = async () => {
    if (
      name === "" ||
      email === "" ||
      subject === "" ||
      message === "" ||
      phone === "" ||
      company === ""
    ) {
      setError(true);
      return;
    } else {
      setError(null);
    }

    const formData = new FormData();
    formData.append("_wpcf7_unit_tag", "wpcf7-f9986-p9984-o2");
    formData.append("your-name", name);
    formData.append("your-email", email);
    formData.append("your-subject", subject);
    formData.append("your-message", message);
    formData.append("your-phone", phone);
    formData.append("your-company", company);

    try {
      const response = await fetch(
        `${BASE_URL}/wp-json/contact-form-7/v1/contact-forms/9986/feedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Origin: `${BASE_URL}`,
          },
          body: formData,
        }
      );

      const data = await response.json();

      if (data.status === "mail_sent") {
        setError(false);
        notify("Inquiry submission successfully!", "success");
        setName("");
        setEmail("");
        setMessage("");
        setPhone("");
        setCompany("");
        setSubject("");
      } else {
        notify("Inquiry submission failed!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Container>
      <Box py={6}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center">
              <img src={InfoIcon} alt="" />
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                問い合わせ種類
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                select
                fullWidth
                defaultValue={"選択してください"}
                value={subject}
                variant="outlined"
                label={!subject ? "選択してください" : ""}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  height: "55px",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#898989",
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value="仕事（プロジェクト）の相談をしたい">
                  仕事（プロジェクト）の相談をしたい
                </MenuItem>
                <MenuItem value="採用について聞きたい">
                  採用について聞きたい
                </MenuItem>
                <MenuItem value="その他">その他</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {/* 会社名/組織名 */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center">
              <img src={InfoIcon} alt="" />
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                会社名/組織名
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="例) 株式会社あすなる"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  height: "55px",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& ::placeholder": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* お名前 */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center">
              <img src={InfoIcon} alt=""/>
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                お名前
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="例) 山田太郎"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  height: "55px",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a", // Add this for the focused state
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the default MUI border
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& ::placeholder": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* メールアドレス */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center">
              <img src={InfoIcon} alt=""/>
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                メールアドレス
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="例) info@sample.co.jp"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  height: "55px",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a", // Add this for the focused state
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the default MUI border
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& ::placeholder": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* 電話番号 */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center">
              <img src={InfoIcon} alt=""/>
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                電話番号
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="例) 08012341234"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  height: "55px",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a", // Add this for the focused state
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the default MUI border
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& ::placeholder": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* お問い合わせ内容 */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="baseline">
              <img src={InfoIcon} alt=""/>
              <Typography
                variant="h6"
                sx={{
                  ml: 1,
                  fontFamily: "Noto Sans JP, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#000000",
                }}
              >
                お問い合わせ内容
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                multiline
                rows={11}
                placeholder="あなたのメッセージ"
                variant="outlined"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  fontFamily: "Noto Sans JP, sans-serif",
                  "&:hover": {
                    border: "1px solid #9a9a9a",
                  },
                  "&.Mui-focused": {
                    border: "1px solid #9a9a9a", // Add this for the focused state
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the default MUI border
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                  "& ::placeholder": {
                    fontFamily: "Noto Sans JP, sans-serif",
                    fontSize: "14px",
                    fontWeight: 400,
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4} container alignItems="center"></Grid>
            <Grid item xs={12} sm={8}>
              {/* <Button
                variant="contained"
                color="warning"
                type="submit"
                sx={{
                  width: "auto",
                  borderRadius: "30px",
                  fontSize: "16px",
                  py: 1,
                  padding:'10px 10px 10px 20px'
                }}
              >
                送信
              </Button> */}
              {error ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: 700,
                    fontFamily: "Noto Sans JP, sans-serif",
                  }}
                >
                  Please fill all details
                </Typography>
              ) : (
                error === false && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "green",
                      fontSize: "14px",
                      fontWeight: 700,
                      fontFamily: "Noto Sans JP, sans-serif",
                    }}
                  >
                    Inquiry submission successfully!
                  </Typography>
                )
              )}
              <Box
                sx={{
                  mt: {
                    xs: 0,
                    sm: 4,
                  },
                }}
                onClick={() => handleSubmit()}
              >
                <CustomButton text={"送信"} />
              </Box>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "start",
                  mt: 3,
                  color: "#898989",
                  fontSize: "14px",
                  fontWeight: 700,
                  fontFamily: "Noto Sans JP, sans-serif",
                }}
              >
                プライバシーポリシーについて同意したものとみなされます
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default InquiryForm;
