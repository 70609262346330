import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Rounddesign from "../../assets/images/rounddesign.svg";

export default function ProductCard({ item, index, clr }) {
  return (
    <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginBottom: "40px" }}>
      <Card
        sx={{
          background: clr
            ? "linear-gradient(195.57deg, rgba(248, 182, 45, 0.1) 7.6%, rgba(234, 85, 20, 0.1) 90.09%)"
            : "#FFFFFF",
          borderRadius: 4,
          height: "410px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s",
          position: "relative",
          "&:hover": {
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            // height: "330px",
          }}
        >
          <Stack px={4}>
            <Stack
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#D4D4D4",
                  fontSize: "60px",
                  fontWeight: 700,
                  lineHeight: "108px",
                  textAlign: "start",
                }}
              >
                0{index + 1}
              </Typography>
            </Stack>
            <Stack spacing={2} mt={6}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#FF6000",
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}
              >
                {item?.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#898989",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "25px"
                                }}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: item?.content?.rendered }} /> */}
                {item?.description}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
        <img
          src={Rounddesign}
          alt="Circle Arrow"
          style={{
            position: "absolute",
            width: "40px",
            height: "40px",
            ...(index === 0 && {
              top: "-140px",
              right: "-120px",
              width: "285px",
              height: "285px",
            }),
            ...(index === 1 && {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "285px",
              height: "285px",
            }),
            ...(index === 2 && {
              bottom: "-140px",
              right: "-120px",
              width: "285px",
              height: "285px",
            }),
          }}
        />
      </Card>
    </Grid>
  );
}
