import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1014 from "../../assets/images/Frame1014.svg";
import Golden from "../../assets/images/goldpartner.png";
import Serv1 from "../../assets/images/serv1.svg";
import Serv2 from "../../assets/images/serv2.svg";
import Serv3 from "../../assets/images/serv3.svg";
import Serv4 from "../../assets/images/serv4.svg";
import Partner from "../../components/Partner";
import GradientHeader from "../../components/product/GradientHeader";
import AccordionScreen from "../../components/system-development/AccordionScreen";
import SpecializedDevelopment from "../../components/system-development/SpecializedDevelopment";
import StartOfDevelopment from "../../components/system-development/StartOfDevelopment";
import SystemStrengthSection from "../../components/system-development/SystemStrength";
const headtitle = "Odoo Solution";
const headsubTitle = "Odooソリューション";
const headsubdec = "サービス > Odooソリューション";
const dataForService = [
  {
    title: "柔軟性の高さ",
    description:
      "Odooはオープンソースのプラットフォームであり、柔軟性が高い。カスタマイズや拡張が容易で、ビジネスの特定のニーズに合わせて調整できます。",
    imgSrc: Serv1,
  },
  {
    title: "使いやすいインターフェース",
    description:
      "ユーザーフレンドリーなインターフェースは、初心者にも親しみやすく、学習コストが低い。直感的な操作で業務を効率的に行えます。",
    imgSrc: Serv2,
  },
  {
    title: "クラウドベースのアクセス可能性",
    description:
      "クラウドベースのソリューションは、いつでもどこでもアクセス可能。リモートワークや移動中でもデータにアクセスでき、ビジネスの柔軟性を高めます。",
    imgSrc: Serv3,
  },
  {
    title: "グローバル展開に適した多言語対応",
    description:
      "多言語・多通貨に対応するOdooは、グローバル展開に適しています。異なる地域や市場においても、ローカライズされたサービスを提供できます。",
    imgSrc: Serv4,
  },
];
const serviceTitle = "What Odoo solutions can solve";
const ServicesubTitle = "Odooソリューションで解決できること";
const dataForSystemOdoo = [
  {
    title: "1つのプラットフォームで解決",
    description:
      "Odooソリューションは、複数の課題を1つの統合プラットフォームで解決します。",
    imgSrc: Frame1014,
  },
  {
    title: "情報一元化",
    description:
      "統合データベースによる情報共有は迅速な意思決定を支援し、ビジネス全体の効率を高めます。",
    imgSrc: Frame1014,
  },
  {
    title: "業務プロセスの最適化",
    description:
      "自動化されたワークフローにより、繰り返し作業や手作業の削減、業務プロセスの最適化を実現します。",
    imgSrc: Frame1014,
  },
  {
    title: "1つのプラットフォームで解決",
    description:
      "モジュールの追加やカスタム開発により、ニーズに合わせたソリューションを構築し、競争力を維持・強化します。",
    imgSrc: Frame1014,
  },
];
const systemTitleOdoo = "What Odoo solutions can solve";
const systemsubTitleOdoo = "Odooソリューションで解決できること";
const dataForIntro = [
  {
    no: "01",
    title: "お問い合わせ",
    description:
      "まずは資料請求、お問合せをいただき、ご希望の業務内容を教えていただきます。案件内容、スケジュール、費用感、チーム構成等、案件概要をヒヤリングし、貴社にとって最適なプランをご提案いたします。ご提案内容・お見積りにご納得いただけるまで、複数回お打ち合わせを行い、ご提案をさせて頂きます。",
  },
  {
    no: "02",
    title: "インドとのＷeb会議",
    description:
      "チーム編成が決まりましたら、日本側、インド側のスタッフを含めてWeb会議を設定いたします。Ｗeb会議で、日本側、インド側の役割分担、コミュニケーション体制、セキュリティ対策、スタッフの日本語レベル、現地スタッフの働く環境等の様々な状況をご説明いたします。 ",
  },
  {
    no: "03",
    title: "ご契約",
    description:
      "ご契約は、グローバルジャパンネットワークとのご契約となります。ご契約はすべて日本語で行いますので、ご安心ください。内容に問題が無ければご契約の締結となります。 ",
  },
  {
    no: "04",
    title: "開発立ち上げ前支援",
    description:
      "開発立ち上げ前に、開発資料をいただき日本側チーム、現地チーム交えて、開発がスムーズに立ち上がるよう調整を行います。 ",
  },
  {
    no: "05",
    title: "開発スタート",
    description:
      "プロジェクトの定期ミーティングを行い、進捗状況を適切にモニタリングを致します",
  },
];
const systemTitleFlow = "Introduction flow";
const systemsubTitleFlow = "導入の流れ";
const partnerTitle = "Odoo Solution";
const partnerSubTitle = "Odooソリューション";
const partnerImg = Golden;
const dataForAccordion = [
  {
    title: "Odooソリューションの導入にはどれくらいの時間がかかりますか？",
    answer:'導入にかかる時間は、ビジネスの要件やカスタマイズの範囲によって異なります。基本的な導入は1～3か月程度で完了しますが、複雑なカスタマイズや統合が必要な場合はさらに時間がかかることがあります。詳細なスケジュールはプロジェクト開始時にご提案します。'
  },
  {
    title: "Odooソリューションはどのような業種に適していますか？",
    answer:'Odooは、製造業、小売業、サービス業、物流、医療など幅広い業種に対応しています。モジュールベースの構造により、業種に応じた機能を柔軟に選び、ビジネスに合わせたシステム構築が可能です。特に、中小企業や成長企業に適したソリューションです。'
  },
  {
    title: "カスタマイズや追加機能のリクエストは可能ですか？",
    answer:'はい、Odooはカスタマイズ性が高く、特定の業務要件に合わせた機能の追加や変更が可能です。API連携やカスタムモジュールの開発も対応しており、お客様のニーズに合わせた柔軟なソリューションを提供します。'
  },
  {
    title: "Odooソリューションの導入にはどれくらいの時間がかかりますか？",
    answer:'Odooの導入期間はプロジェクトの規模や複雑さに依存します。シンプルな構成の場合、数週間から1か月程度で完了します。高度なカスタマイズや多くのモジュールを使用する場合は、3か月以上かかることもあります。'

  },
];
const titleAccordion = "よくあるご質問";
const ServicesOdooSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Odooソリューション | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <Partner
        img={partnerImg}
        title={partnerTitle}
        subTitle={partnerSubTitle}
      />
      <SpecializedDevelopment
        data={dataForSystemOdoo}
        title={systemTitleOdoo}
        subTitle={systemsubTitleOdoo}
      />
      <SystemStrengthSection
        data={dataForService}
        title={serviceTitle}
        subTitle={ServicesubTitle}
      />
      <StartOfDevelopment
        data={dataForIntro}
        title={systemTitleFlow}
        subTitle={systemsubTitleFlow}
      />
      <AccordionScreen data={dataForAccordion} title={titleAccordion} />
    </>
  );
};
export default ServicesOdooSolutions;