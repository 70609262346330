import { Box, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Circle from "../../assets/images/blog/circle.svg";
import PaginationComponent from "../../common/pagination";
import PostCard from "../../common/Post/Card";
import { usePostStore } from "../../store/postStore";

const OtherPosts = () => {
  const { posts, getPosts } = usePostStore();

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;

  useEffect(() => {
    setCurrentPage(1);
    getPosts("all");
  }, [getPosts]);


  useEffect(() => {
    setCurrentPage(1);
  }, [posts]);
  // Calculate the total number of pages
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Get the current posts for the page
  const indexOfLastBlog = currentPage * postsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstBlog, indexOfLastBlog);

  // Handle pagination page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={Circle}
          alt="Background design"
          sx={{
            height: { xs: 20, sm: "492px" },
            ml: 3,
            position: "absolute",
            // height: "492px",
            width: "492px",
            top: "3em",
            right: "-15em",
            zIndex: "-1",
          }}
        />
        <Container>
          <Stack pt={5} sx={{ position: "relative" }}>
            <Grid container spacing={4} mt={6}>
              {currentPosts.map((post) => (
                <Grid item xs={12} sm={6} md={4} key={`${post.id}-post-card`}>
                  <PostCard slug={'blogs'} post={post} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Container>
      </Box>

      <PaginationComponent
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default OtherPosts;
