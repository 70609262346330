import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Arrowforward from "../../../assets/images/arrowforward.svg";
import Magicproduct from "../../../assets/images/magicproduct.svg";
import Otherhouses from "../../../assets/images/otherhouses.svg";
import { useNavigate } from "react-router-dom";

const GradientHeader = ({ subdesc, subTitle, title }) => {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        backgroundImage: `url(${Magicproduct})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "200px",
        color: "#000000",
        display: "flex",
        justifyContent: "center",
        // marginTop:10
      }}
    >
      <Container>
        <Stack py={{ xs: 3, sm: 5 }}>
          <Stack>
            <Stack>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "20px", sm: "24px" },
                  lineHeight: { xs: "28px", sm: "33px" },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "30px", sm: "50px" },
                  lineHeight: { xs: "45px", sm: "70px" },
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {subTitle}
              </Typography>

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <img
                    style={{ width: "18px", height: "18px", cursor: "pointer" }}
                    src={Otherhouses}
                    alt="Other houses"
                    onClick={() => navigate('/')}
                  />
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Arrowforward}
                    alt="Arrow forward"
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: "12px", sm: "14px" },
                      lineHeight: { xs: "16px", sm: "19px" },
                      color: "#898989",
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                    }}
                  >
                    {subdesc}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default GradientHeader;
