import React from "react";
import { Button } from "@mui/material";

const CustomNewsButton = ({ label, selectedButton, onClick }) => {
  const buttonStyles = (isSelected) => ({
    color: isSelected ? "#fff" : "#898989",
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    fontSize: { xs: "14px", sm: "14px" },
    fontWeight: 700,
    fontFamily: "Noto Sans JP, sans-serif",
    padding: "10px 30px 10px 30px",
    height: "40px",
    background: isSelected
      ? "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)"
      : "#fff",
    border: isSelected
      ? "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)"
      : "1px solid #898989",
    transition: "background 0.3s ease, border-color 0.3s ease",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
      borderColor: "transparent",
      boxShadow: "none",
      color:  "#fff",
    },
  });

  return (
    <Button
      variant="contained"
      sx={buttonStyles(selectedButton === label)}
      onClick={() => onClick(label)}
    >
      {label}
    </Button>
  );
};

export default CustomNewsButton;
