import {
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Close from "../../../assets/images/close.svg";

const RoundCard = ({ data, title, subTitle, subTitleMin, clr }) => {
  return (
    <Stack backgroundColor={"#F8F8F8"}>
      <Container>
        <Stack py={5}>
          <Stack
            display={{ xs: "none", md: "flex" }}
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent={"center"}
          >
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",
                  }}
                >
                  グローバルIT技術
                </Typography>
              </CardContent>
            </Card>
            <img
              src={Close}
              alt="Close"
              style={{ width: "40px", height: "40px" }}
            />
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",

                  }}
                >
                  高度ITエンジニア
                </Typography>
              </CardContent>
            </Card>
            <img
              src={Close}
              alt="Close"
              style={{ width: "40px", height: "40px" }}
            />
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",

                  }}
                >
                  高度ITエンジニア
                </Typography>
              </CardContent>
            </Card>
          </Stack>

          <Stack
            display={{ xs: "flex", md: "none" }}
            spacing={4}
            alignItems="center"
            justifyContent={"center"}
          >
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",
                  }}
                >
                  グローバルIT技術
                </Typography>
              </CardContent>
            </Card>
            <img
              src={Close}
              alt="Close"
              style={{ width: "40px", height: "40px" }}
            />
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",
                  }}
                >
                  高度ITエンジニア
                </Typography>
              </CardContent>
            </Card>
            <img
              src={Close}
              alt="Close"
              style={{ width: "40px", height: "40px" }}
            />
            <Card
              sx={{
                backgroundColor: "#FF60000D",
                borderRadius: 50,
                height: { xs: "250px", sm: "300px" },
                width: { xs: "250px", sm: "300px" },
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FF6000",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    textAlign: "center",
                  }}
                >
                  高度ITエンジニア
                </Typography>
              </CardContent>
            </Card>
          </Stack>

          <Typography
            variant="body2"
            sx={{
              color: "#000000",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "32px",
              mt: 4,
              textAlign: "start",
              fontFamily:'Inter, sans-serif'
            }}
          >
            最先端のグローバルIT技術を駆使して、新しいビジネスやサービスを開発するプロデュースを行います。
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#000000",
              fontSize: "20.5px",
              fontWeight: 400,
              lineHeight: "32px",
              mt: 1,
              textAlign: "start",
              fontFamily:'Inter, sans-serif'

            }}
          >
            高いITスキルを持つグローバルITエンジニアによるシステム開発と運用経験に支えられた実現可能性の高いコンサルティングサービスを提供します。
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#000000",
              fontSize: "20.5px",
              fontWeight: 400,
              lineHeight: "32px",
              mt: 1,
              textAlign: "start",
              fontFamily:'Inter, sans-serif'

            }}
          >
          クライアントと協力し、イノベーションを起こす新ビジネスやサービスの企画からITによる実装、立ち上げまでを迅速に行います。
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#000000",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "32px",
              mt: 1,
              textAlign: "start",
              fontFamily:'Inter, sans-serif'

            }}
          >
          また、企業の未来を形作り、実現するために、先端技術を活用し、開発や運用の力を使いながら、課題解決から実装、現場での定着まで、徹底的にサポートいたします。
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
};
export default RoundCard;
