import {
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../../../common/button/CustomButton";
import ProductCard from "../../../common/Product/Card";
import { usePostStore } from "../../../store/postStore";
const InsightGlobal = ({ data, title, subTitle, subTitleMin, clr, navliLink }) => {

  const { getPosts } = usePostStore();

  useEffect(() => {
    getPosts("products");
  }, [getPosts]);

  return (
    <Stack backgroundColor={"#FFFFFF"}>
      <Container>
        <Stack py={5}>
          <Stack>
            <Stack>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background:
                    "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "33px",
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  fontSize: "50px",
                  lineHeight: "70px",
                  color: "#000000",
                }}
              >
                {subTitle}
              </Typography>
            </Stack>
          </Stack>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  color: "#000000",
                }}
              >
                {subTitleMin}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} mt={1}>
            {data.map((item, index) => (
              <ProductCard item={item} index={index} clr={true} />
            ))}
          </Grid>
          {!clr && (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // onClick={() => navigate("/お問い合わせ-2/")}
              onClick={() => (window.location.href = navliLink)}

            >
              <CustomButton text="詳細を見る" navliLink={navliLink} />
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
export default InsightGlobal;
