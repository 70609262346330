import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { getAchievements, getPostDetail, getPosts, getCategories } from "../services/api/post/getPosts";
import { getTags } from "../services/api/post/getTags";

export const usePostStore = create(
  immer((set) => ({
    posts: [],
    post: {},
    casestudies: [],
    achievements: [],
    tags: [],
    services: [],
    products: [],
    strengths: [],
    projects: [],
    members: [],
    examples: [],
    categories: [],
    category: null,
    loading: false,
    tagsLoading: false,
    achievementsLoading: false,
    getPosts: async (slug) => {
      set({ loading: true, posts: [] });
      try {
        const response = await getPosts(slug);
        if (response.success && response.data) {
          if (slug === 'services') {
            set({ services: response.data });
          } else if (slug === 'casestudies') {
            set({ casestudies: response.data });
          } else if (slug === 'products') {
            set({ products: response.data });
          } else if (slug === 'strengths') {
            set({ strengths: response.data });
          } else if (slug === 'projects') {
            set({ projects: response.data });
          } else if (slug === 'members') {
            set({ members: response.data });
          } else if (slug === 'examples') {
            set({ examples: response.data });
          } else {
            set({ posts: response.data });
          }
        }
        set({ loading: false });
      } catch (error) {
        set({ loading: false });
      }
    },
    getAchievements: async (slug) => {
      set({ achievementsLoading: true, achievements: [] });
      try {
        const response = await getAchievements(slug);
        if (response.success && response.data) {
          set({ achievements: response.data });
        }
        set({ achievementsLoading: false });
      } catch (error) {
        set({ achievementsLoading: false });
      }
    },
    getCategories: async (slug) => {
      try {
        const response = await getCategories(slug);
        if (response) {
          set({ categories: response });
        }
      } catch (error) {
      }
    },
    getTags: async () => {
      set({ tagsLoading: true });
      try {
        const response = await getTags();
        set({ tagsLoading: false });
        if (response.success && response.data) {
          set({ tags: response.data });
        }
      } catch (error) {
        set({ tagsLoading: false });
      }
    },
    getPostDetail: async (id) => {
      set({ loading: true});
      try {
        const response = await getPostDetail(id);
        if (response.success && response.data) {
          const filteredPosts = response.data.filter(post =>
            post.title.rendered.toLowerCase().includes(id.toLowerCase())
          );
          set({ post: filteredPosts[0] });
        }
        set({ loading: false });
      } catch (error) {
        set({ loading: false });
      }
    },
    setCategory: async (slug) => {
      set({ category: slug })
    }
  }))
);
