import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Question from "../../../assets/images/question.svg";
import Rounddesign from "../../../assets/images/rounddesign.svg";

const CarouselForEngineering = ({ data, title, subTitle }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      sx={{
        position: "relative",
        overflow: "hidden",
        height: isMobile ? 400 : 700,  
        padding: isMobile ? "20px" : "0",  
      }}
    >
      <Container >
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: isMobile ? "center" : "flex-start", 
            height: isMobile ? 400 : 700,  
            textAlign: isMobile ? "center" : "left",  
          }}
        >
          <img
            src={Question}
            alt="Background"
            style={{
              height: isMobile ? "80px" : "100px",  
              width: isMobile ? "80px" : "100px",
              marginBottom: isMobile ? "16px" : "0",
            }}
          />
          <Typography
            variant={isMobile ? "body1" : "h6"}
            gutterBottom
            sx={{
              color: "#000000",
              fontWeight: 700,
              fontSize: isMobile ? "18px" : "20px",  
              lineHeight: isMobile ? "24px" : "28px",
            }}
          >
            不安を解消してくれた
          </Typography>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            gutterBottom
            sx={{
              background:
                "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
              fontSize: isMobile ? "18px" : "16px",
              lineHeight: isMobile ? "24px" : "22px",
            }}
          >
            ２５歳の男性 – ウェブ開発者
          </Typography>

         <Stack>
         <Typography
            mt={isMobile ? 2 : 3}
            variant={isMobile ? "body2" : "h6"}
            gutterBottom
            sx={{
              color: "#000000",
              fontWeight: 400,
              fontSize: isMobile ? "16px" : "20px",
              lineHeight: isMobile ? "22px" : "32px",
              fontFamily:'Inter, sans-serif'
            }}
          >
            日本に来て働くにあたり、よく分からないことも多く、心配なこともありましたが、日本でのビザ
          </Typography>
          <Typography
            mt={isMobile ? 0 : 0}
            variant={isMobile ? "body2" : "h6"}
            gutterBottom
            sx={{
              color: "#000000",
              fontWeight: 400,
              fontSize: isMobile ? "16px" : "20px",
              lineHeight: isMobile ? "22px" : "32px",
              fontFamily:'Inter, sans-serif'
            }}
          >
            の申請や税金のことなどについて親身に相談に乗っていただき、不安を解消してくれました。
          </Typography>
         </Stack>
        </Stack>
        <Box
          component="img"
          src={Rounddesign}
          alt="Round Design"
          sx={{
            position: "absolute",
            top: isMobile ? 50 : 100,
            right: isMobile ? -100 : -180, 
            zIndex: -1,
            width: isMobile ? "300px" : "466px", 
            height: isMobile ? "300px" : "466px",
          }}
        />
      </Container>
    </Stack>
  );
};

export default CarouselForEngineering;
