import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import InquiryForm from "../../components/Inquiry";
import GradientHeader from "../../components/product/GradientHeader";

const headtitle = "inquiry";
const headsubTitle = "お問い合わせ";
const headsubdec = "お問い合わせ";

const InquiryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          オフショアモバイルアプリ開発 | オフショアAndroidアプリ開発サービス |
          アプリ開発のアウトソーシングインドのコスト | オフショア アプリ 開発
        </title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />

      <Container maxWidth="md">
        <Container>
          <Box
            sx={{
              py: { xs: 2, md: 9 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                backgroundClip: "text", // Standard background-clip property
                WebkitBackgroundClip: "text", // Ensures WebKit browsers support it
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontFamily: "Noto Sans JP, sans-serif",
                fontSize: {
                  xs: "14px",
                  sm: "24px",
                },
              }}
            >
              Inquiry form
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: {
                  xs: "18px",
                  sm: "38px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
                lineHeight: "53px",
              }}
            >
              お問い合わせフォーム
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              // mt={6}
              // mt= {
              //   xs: "14px",
              //   sm: "24px",
              // }
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
                mt: {
                  xs: 3,
                  sm: 5,
                },
                lineHeight: "36px",
              }}
            >
              ご意見・ご相談・お見積りなどお気軽にお問い合わせください。
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              // mt={6}
              // mt= {
              //   xs: "14px",
              //   sm: "24px",
              // }
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
                lineHeight: "36px",
              }}
            >
              内容を確認後に担当者よりご連絡させていただきます。
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              // mt={6}
              // mt= {
              //   xs: "14px",
              //   sm: "24px",
              // }
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
                mt: {
                  xs: 3,
                  sm: 3,
                },
                lineHeight: "36px",
              }}
            >
              下記よりお問い合わせになりたい項目を選択後、フォーマットにご記入いただき内容を
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              // mt={6}
              // mt= {
              //   xs: "14px",
              //   sm: "24px",
              // }
              sx={{
                fontWeight: 400,
                fontSize: {
                  xs: "14px",
                  sm: "20px",
                },
                color: "#000000",
                fontFamily: "Noto Sans JP, sans-serif",
                lineHeight: "36px",
              }}
            >
              ご確認後に「送信」ボタンから送信してください。
            </Typography>
          </Box>
        </Container>
        <hr
          style={{
            borderTop: "1px solid #EEEEEE",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
        <InquiryForm />
      </Container>
    </>
  );
};

export default InquiryPage;
