import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CardIMage from "../../assets/images/cardImg.png";
import RightArrow from "../../assets/images/rightArrow.svg";
import PaginationComponent from "../../common/pagination";
import ButtonGrid from "../../components/News/ButtonGrid";
import NewsCard from "../../components/News/NewsCard";
import GradientHeader from "../../components/product/GradientHeader";

const headtitle = "notice";
const headsubTitle = "お知らせ";
const headsubdec = "お知らせ";

const newsCardData = [
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "プレスリリース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "プレスリリース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "ニュース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "プレスリリース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "ニュース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "プレスリリース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "事例",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "ニュース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
  {
    Cardtitle: "画像",
    CardDate: "2023.12.07",
    ButtonText: "プレスリリース",
    CardIMage: CardIMage,
    RightArrow: RightArrow,
    cardContent:
      "テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト",
  },
];
const NewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>お知らせ | Global Japan Network</title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <ButtonGrid />
      <hr
        style={{
          borderTop: "1px solid #EEEEEE",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />
      {newsCardData.map((item, index) => (
        <>
          <NewsCard
            key={index}
            Cardtitle={item.Cardtitle}
            CardDate={item.CardDate}
            ButtonText={item.ButtonText}
            CardContent={item.cardContent}
            CardIMage={CardIMage}
            RightArrow={RightArrow}
          />
          <hr
            style={{
              borderTop: "1px solid #EEEEEE",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          />
        </>
      ))}
      <PaginationComponent totalPages={5} />
    </>
  );
};

export default NewsPage;
