import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { BASE_URL } from "../../constants/portConstants";
import { usePostStore } from "../../store/postStore";

const UpdateImageSrc = (content) => {
  return content?.replace(/srcset="([^"]+)"/g, `srcset="${BASE_URL}$1"`);
};

const removeShortcodes = (content) => {
  return content?.replace(/\[.*?\]/g, '');
}

const PostDetail = ({ post, category }) => {

  const { loading } = usePostStore();

  const shortCodes = removeShortcodes(post?.content?.rendered);

  const updatedContent = UpdateImageSrc(shortCodes);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [post]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Card
        sx={{
          backgroundColor: "#F6F6F6",
          borderRadius: 4,
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          padding: 2,
          margin: 3,
          width: 800,
        }}
      >
        {loading ? (
          <React.Fragment>
            <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
          </React.Fragment>
        ) : (
          <Typography
            variant="h5"
            sx={{
              color: "#FF6000",
              fontWeight: 700,
              marginBottom: 2,
              fontFamily: "Noto Sans JP, sans-serif",
              fontSize: "2rem",
            }}
          >
            {post?.title?.rendered}
          </Typography>)}

        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          {
            loading ? (
              <Skeleton
                animation="wave"
                height={30}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: "#ECECEC",
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: "Noto Sans JP, sans-serif",
                }}
              >
                {new Date(post?.date)
                  .toLocaleDateString("en-CA")
                  .replace(/-/g, ".")}
              </Typography>)}
          {
            loading ? (
              <Skeleton
                animation="wave"
                height={30}
                width="20%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: "#FF600030",
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: "#f18b22",
                  fontWeight: 700,
                  fontFamily: "Noto Sans JP, sans-serif",
                  textTransform: "capitalize",
                  marginLeft: 2,
                }}
              >
                {category}
              </Typography>)}
        </Grid>
        <CardContent>
          {loading ? (
            <React.Fragment>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ marginBottom: 6 }}
              >
                <Skeleton animation="wave" height={40} width={280} />
              </Box>              <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={30} style={{ marginBottom: 6 }} />
            </React.Fragment>
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: "#4A4A4A",
                lineHeight: 1.8,
                fontFamily: "Noto Sans JP, sans-serif",
                "& img": {
                  width: "100%",
                  height: "auto",
                  objectFit: "contain"
                },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: updatedContent }} />
            </Typography>)}
        </CardContent>

        {/* <Box padding={3}>
          <Typography
            variant="h2"
            sx={{ fontWeight: 700, color: "#FF6600", fontSize: "2rem" }}
          >
            Insight Globalの詳細はこちらから
          </Typography>

          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{
              marginTop: 2,
              border: "1px solid #ccc",
              padding: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Insight Global
              </Typography>
              <Typography variant="body2">
                <a
                  href="https://insightglobal.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "grey",
                    textDecoration: "underline",
                  }}
                >
                  https://insightglobal.jp/
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src={global} // Use your SVG file path
                alt="Insight Global Logo"
                style={{
                  maxHeight: "100px",
                  width: "100%",
                  objectFit: "contain",
                  borderRadius: "4px",
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ marginY: 2 }} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid
              item
              xs={4}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontWeight: 700 }}
              >
                SHARE
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid container spacing={1}>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={twitter} // Use the correct path to your SVG
                      alt="Twitter Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebook} // Use the correct path to your SVG
                      alt="Facebook Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://web.whatsapp.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={bi} // Use the correct path to your SVG
                      alt="WhatsApp Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={pocket} // Use the correct path to your SVG
                      alt="LinkedIn Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={line} // Use the correct path to your SVG
                      alt="LinkedIn Icon"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={url} // Use the correct path to your SVG
                      alt="LinkedIn Icon"
                      style={{ width: "43px", height: "56px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box> */}
      </Card>
    </Box>
  );
};

export default PostDetail;
