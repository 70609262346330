import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Banner from "../../assets/images/footerImg.png";
import MobileBanner from "../../assets/images/mFooterBg.svg";
import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import Arrow from "../../assets/images/arrow.png";
import ArrowO from "../../assets/images/arrowOrrange.svg";
import Logo from "../../assets/images/logo.svg";
function Footer() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const currentPath = location.pathname;

  return (
    <Box
      mt={currentPath === "/%E4%BC%9A%E7%A4%BE%E6%A6%82%E8%A6%81/" ? 15 : 0}
      sx={{ position: "relative", width: "100%", bottom: "0px", left: "0px" }}
    >
      <Grid item xs={12} md={3} sx={{ background: "#f7f7f7" }}>
        <img
          className="footer-banner"
          src={isMobile ? MobileBanner : Banner}
          alt="Global Japan Network"
          width={"100%"}
          height={"100%"}
          layout="responsive"
        />
      </Grid>

      <Container maxWidth="md">
        <Box
          sx={{
            position: "absolute",
            top: {
              xs: "16%",
              sm: "24%",
            },
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Grid item xs={10} md={10} lg={8}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 600,
                  fontSize: {
                    xs: "16px",
                    sm: "20px",
                  },
                  mb: {
                    xs: 2,
                    sm: 4,
                  },
                  color: "#FFFFFF",
                  lineHeight: "28px",
                }}
              >
                依頼内容が未確定の場合もお気軽にご相談ください
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 600,
                  fontSize: {
                    xs: "12px",
                    sm: "20px",
                  },
                  color: "#FFFFFF",
                  lineHeight: "28px",
                }}
              >
                システム開発・グローバル人材の派遣やご紹介をご検討の方はこちらからお問い合わせください。
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 600,
                  fontSize: {
                    xs: "12px",
                    sm: "20px",
                  },
                  color: "#FFFFFF",
                  lineHeight: "28px",
                }}
              >
                プロジェクトごとに、適したチーム体制や進め方をご提案します。
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                },
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                },
                width: "100%",
              }}
            >
                <Button
                  onClick={() => navigate("/お問い合わせ-2/")}
                  variant="contained"
                  sx={{
                    color: "#FF6000",
                    display: "flex",
                    justifyContent:'center',
                    alignItems: "center",
                    borderRadius: "30px",
                    height: "50px",
                    fontSize: "14px",
                    fontWeight: 700,
                    background: "#FFFFFF",
                    padding: "10px 10px 10px 20px",
                    fontFamily: "Noto Sans JP, sans-serif",
                    "&:hover": {
                      background:
                        "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                      color: "#FFFFFF",
                    },
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  お問い合わせ
                  <Box
                    component="img"
                    src={isHovered ? Arrow : ArrowO}
                    alt="Button image"
                    sx={{ height: 30, ml: 3 }}
                  />
                </Button>
            
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box sx={{ paddingBottom: "20px", backgroundColor: "#f7f7f7" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              px: { xs: 2, md: 10 },
              pt: { xs: 5, md: 7 },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={2.5}>
                <img
                  src={Logo}
                  alt="Global Japan Network"
                  width={166}
                  height={60}
                />
              </Grid>
              <Grid item xs={6} md={2.5} mt={2}>
                <Stack spacing={2}>
                  <Typography
                    variant="h6"
                    mb={3}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#000000;",
                    }}
                  >
                    私たちについて
                  </Typography>
                  <Stack>
                    <Typography
                      onClick={() =>
                        navigate("/会社概要/", {
                          state: { propName: "ミッション" },
                        })
                      }
                      sx={{
                        marginTop: 1,
                        fontSize: "14px",
                        fontWeight: 400,
                        cursor: "pointer",
                        lineHeight: "19px",
                        color: "#898989",
                        "&:hover": {
                          color: "#EA5514",
                        },
                      }}
                    >
                      ミッション
                    </Typography>
                  </Stack>

                  <Typography
                    onClick={() => navigate("/会社概要/")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    会社概要
                  </Typography>
                  <Typography
                    onClick={() =>
                      navigate("/会社概要/", {
                        state: { propName: "経営メンバー" },
                      })
                    }
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    経営メンバー
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={2.5} mt={2}>
                <Typography
                  mb={2}
                  variant="h6"
                  sx={{ fontSize: "16px", fontWeight: 700, color: "#000000;" }}
                >
                  プロダクト
                </Typography>
                <Stack mt={3}>
                  <Typography
                    onClick={() => navigate("/products/")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    Insight global
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={2.5} mt={2}>
                <Stack spacing={2}>
                  <Typography
                    mb={3}
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#000000;",
                    }}
                  >
                    サービス
                  </Typography>
                  <Stack>
                    <Typography
                      onClick={() => navigate("/services/system-development")}
                      sx={{
                        marginTop: 1,
                        fontSize: "14px",
                        fontWeight: 400,
                        cursor: "pointer",
                        lineHeight: "19px",
                        color: "#898989",
                        "&:hover": {
                          color: "#EA5514",
                        },
                      }}
                    >
                      システム開発支援
                    </Typography>
                  </Stack>
                  <Typography
                    onClick={() => navigate("/services/consulting")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    コンサルティング
                  </Typography>
                  <Typography
                    onClick={() => navigate("/services/odoo-solutions")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    Odooソリューション
                  </Typography>
                  <Typography
                    onClick={() => navigate("/services/microsoft-solution")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    Microsoftソリューション
                  </Typography>
                  <Typography
                    onClick={() => navigate("/services/engineering")}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      cursor: "pointer",
                      lineHeight: "19px",
                      color: "#898989",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    人材派遣
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={2} mt={2}>
                <Stack spacing={2}>
                  <Typography
                    onClick={() => navigate("/お問い合わせ-2/")}
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      cursor: "pointer",
                      color: "#000000",
                      "&:hover": {
                        color: "#EA5514",
                      },
                    }}
                  >
                    問い合わせ
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Box
              component="hr"
              sx={{
                borderTop: "1px solid #EEEEEE",
                marginTop: {
                  xs: "2em",
                  md: "4em",
                },
              }}
            />
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
                mt: {
                  xs: 1,
                  md: 2,
                },
              }}
            >
              {/* <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-start" },
                }}
              >
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  <img src={FB} style={{ marginRight: ".5em" }} alt="Facebook" />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                  <img src={Twitter} style={{ marginRight: ".5em" }} alt="Twitter" />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                  <img src={Insta} alt="Instagram" />
                </a>
              </Grid> */}

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "center" },
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#898989" }}
                >
                  © 2024 Global Japan Network Limited
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
