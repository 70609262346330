import React from "react";
import { Button, Box } from "@mui/material";
import Arrow from "../../assets/images/arrow.png";

const CustomButton = ({ text, navi, navliLink }) => {
  return (
    <Button
      variant="contained"
      sx={{
        color: "#fff",
        display: "flex",
        alignItems: "center",
        borderRadius: "30px",
        height: "50px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight:'19px',
        padding: "10px 10px 10px 20px",
        fontFamily: "Noto Sans JP, sans-serif",
        background: "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
        "&:hover": {
          background: "rgb(0 0 0)",
          "& img": {
            transform: "scale(1.3)",
          },
        },
      }}
    >
      {text}
      <Box
        component="img"
        src={Arrow}
        alt="Button icon"
        sx={{
          height: 28,
          ml: 3,
          transition: "transform 0.3s ease",
        }}
      />
    </Button>
  );
};

export default CustomButton;
