import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Frame1006 from "../../assets/images/Frame1006.svg";
import Frame1007 from "../../assets/images/Frame1007.svg";
import Frame1008 from "../../assets/images/Frame1008.svg";
import Rounddesign from "../../assets/images/rounddesign.svg";
import GradientHeader from "../../components/product/GradientHeader";
import InsightGlobal from "../../components/product/InsightGlobal";
import InsightGlobalText from "../../components/product/InsightGlobalText";
import IntroductionFlow from "../../components/product/IntroductionFlow";
import Platform from "../../components/product/Platform";
import ServiceUsage from "../../components/product/ServiceUsage";

const headtitle = "インサイト・グローバル";
const headsubTitle = "Insight Global";
const headsubdec = "Insight Global";

const dataForCard = [
  {
    title: "簡単な手続きで時間短縮",
    description:
      "このツールを使えば、データ収集、データ抽出を簡単に行うことができ、効率的な業務遂行が可能となります。",
    imgSrc: Frame1006,
  },

  {
    title: "財務から在庫管理まで一元化",
    description:
      "財務データはもちろん、人事、労務、在庫など、社内に散在するデータを一元管理します。",
    imgSrc: Frame1007,
  },
  {
    title: "タイムリーなデータ分析",
    description:
      "国内外問わずKPIを常にモニタリングし、スムーズな情報共有と意思決定を実現します。",
    imgSrc: Frame1008,
    roundDesignSrc: Rounddesign,
  },
];

const cardTitle = "What you can do with Insight Global";
const cardSubTitle = "Insight Globalでできること";
const navliLink = "https://insightglobal.jp/solution";
const cardNewSubTitle =
  "「ビジネスを成長を加速させるデータ分析プラットフォーム」 Insight Globalが提供するプラットフォームなら ノーコードで複数のデータを一元管理できるから よりビジネスの成長にコミットしたデータ活用ができます。";

const navi = "achievements";
const ProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          ビジネスインテリジェンス分析レポートソフトウェア |
          ビジネスレポートソフトウェア | 最高のキャッシュフロー管理ソフトウェア
          | 最高の在庫管理ソフトウェア | 最高の在庫管理ソフトウェア |
          在庫管理ソフトウェア | 現金管理ツールとテクニック |
          キャッシュフロー管理ソフトウェア | 財務計画および分析ツール |
          利益分析ソフトウェア
        </title>
      </Helmet>
      <GradientHeader
        title={headtitle}
        subTitle={headsubTitle}
        subdesc={headsubdec}
      />
      <InsightGlobalText />
      <InsightGlobal
        data={dataForCard}
        title={cardTitle}
        subTitle={cardSubTitle}
        navliLink={navliLink}
        subTitleMin={cardNewSubTitle}
      />
      <Platform />
      <ServiceUsage />
      <IntroductionFlow navi={navi} />
    </>
  );
};

export default ProductPage;
