import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Routes from "./routes/index";
import Navbar from "./common/header";
import Footer from "./common/footer";
import { HelmetProvider } from "react-helmet-async";

const getGoogleTranslateLanguage = () => {
  const lang = document.documentElement.getAttribute("lang"); 
  return ["en", "ja"].includes(lang) ? lang : "ja"; 
};

function App() {
  const [language, setLanguage] = useState(getGoogleTranslateLanguage());

  useEffect(() => {
    // Observer to watch for changes on the <html> element
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "lang"
        ) {
          setLanguage(getGoogleTranslateLanguage());
        }
      }
    });

    observer.observe(document.documentElement, {
      attributes: true,
      subtree: false,
    });

    return () => observer.disconnect();
  }, []);

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: `"Noto Sans JP","sans-serif"`,
        fontSize: 15,
        letterSpacing: language === "ja" ? "2px" : "unset",
      },
    },
  });
  return (
    <>
      <CookiesProvider key={`lang-${language}`}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <BrowserRouter>
              <Navbar />
              <Routes />
              <Footer />
            </BrowserRouter>
          </HelmetProvider>
        </ThemeProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
