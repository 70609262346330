import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Rounddesign from "../../../assets/images/rounddesign.svg";


const StartOfDevelopment = ({data, title, subTitle}) => {
  return (
    <Stack
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Container >
        <Stack py={5}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              background:
                "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "33px",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: "50px",
              lineHeight: "70px",
              color: "#000000",
            }}
          >
           {subTitle}
          </Typography>

          <Grid
            container
            py={4}
            spacing={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.map((item, index) => (
              <>
                <Grid item xs={1}>
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#FF600033",
                        fontSize: "70px",
                        fontWeight: 700,
                        lineHeight: "98px",
                      }}
                    >
                      {item.no}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={11}>
                  <Stack direction={"row"} spacing={2} px={{ xs: 0, md: 5 }}>
                    <Stack>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "28px",
                        }}
                      >
                        {item.title}
                      </Typography>

                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontSize: "20px",
                          fontWeight: 400,
                          lineHeight: "32px",
                          fontFamily:"Inter, sans-serif"
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>
        </Stack>
        <Box
          component="img"
          src={Rounddesign}
          alt="Round Design"
          sx={{
            position: "absolute",
            top: -45,
            left: -150,
            zIndex: 1,
            width: "262px",
            height: "262px",
          }}
        />
        <Box
          component="img"
          src={Rounddesign}
          alt="Round Design"
          sx={{
            position: "absolute",
            bottom: -45,
            right: -60,
            width: "157px",
            height: "157px",
            zIndex: 1,
          }}
        />
      </Container>
    </Stack>
  );
};

export default StartOfDevelopment;
