import React from "react";
import FeaturesPage from "../pages/Features";
import ProductPage from "../pages/Product";
import ServicesPage from "../pages/Services";
import SystemDevelopmentPage from "../pages/SystemDevelopment";
import ServicesOdooSolutions from "../pages/ServicesOdooSolutions";
import NewsPage from "../pages/News";
import InquiryPage from "../pages/Inquiry";
import BlogPage from "../pages/Blog";
import AcheivementsPage from "../pages/Acheivements";
import ServiceMicrosoftSolution from "../pages/ServiceMicrosoftSolution";
import Engineering from "../pages/Engineering";
import ServicesConsulting from "../pages/ServicesConsulting";
import AboutUsCompany from "../pages/AboutUsCompany";
import PostDetail from "../pages/PostDetail";

const Landing = React.lazy(() => import("../pages/Landing"));
const routes = [
  {
    path: "/",
    exact: false,
    element: Landing,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/features",
    exact: false,
    element: FeaturesPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/products/",
    exact: false,
    element: ProductPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services",
    exact: false,
    element: ServicesPage,
    isAuthenticated: false,
    isCommon: true,
  },
  // {
  //   path: "/3D/VirtualTour",
  //   exact: false,
  //   element: UnityWebGL,
  //   isAuthenticated: false,
  //   isCommon: true,
  // },
  {
    path: "/achievements",
    exact: false,
    element: AcheivementsPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/会社概要/",
    exact: false,
    element: AboutUsCompany,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services/system-development",
    exact: false,
    element: SystemDevelopmentPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services/odoo-solutions",
    exact: false,
    element: ServicesOdooSolutions,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services/microsoft-solution",
    exact: false,
    element: ServiceMicrosoftSolution,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services/consulting",
    exact: false,
    element: ServicesConsulting,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/services/engineering",
    exact: false,
    element: Engineering,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/news",
    exact: false,
    element: NewsPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/お問い合わせ-2/",
    exact: false,
    element: InquiryPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/category/all/",
    exact: false,
    element: BlogPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/category/culture/",
    exact: false,
    element: BlogPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/category/business/",
    exact: false,
    element: BlogPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/category/education/",
    exact: false,
    element: BlogPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/category/interview/",
    exact: false,
    element: BlogPage,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/:id",
    exact: false,
    element: PostDetail,
    isAuthenticated: false,
    isCommon: true,
  },
];

export default routes;
