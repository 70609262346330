import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../common/button/CustomButton";
import { usePostStore } from "../../../store/postStore";
import { getFeaturedMedia } from "../../../utils/postUtils";
const NumerousProjectsSection = ({
  data,
  isHide,
  title,
  subTitle,
  isGrid,
  isClr,
  navi,
}) => {
  const navigate = useNavigate();
  const { projects, getPosts } = usePostStore();

  useEffect(() => {
    getPosts('projects')
  }, [getPosts])
  return (
    <Stack
      style={{
        backgroundColor: isClr ? "#FFFFFF" : "#F8F8F8",
        marginLeft: isClr ? -21 : 0
      }}
    >
      <Container >
        <Stack py={isClr ? 0 : 5}>
          <Stack>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background:
                  "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "33px",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: "50px",
                lineHeight: "53px",
                color: "#000000",
              }}
            >
              {subTitle}
            </Typography>
          </Stack>

          {!isHide && (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "36px",
                    color: "#000000",
                  }}
                >
                  戦略立案から施策実行まで多様なケースで企業の課題解決をご支援。
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "36px",
                    color: "#000000",
                  }}
                >
                  技術力とコストパフォーマンスに高い評価をいただいています。
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={4} mt={0} px={2}>
            {projects.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={isGrid ? 6 : 4}
                key={index}
                sx={{ marginBottom: "40px" }}
              >
                <Card
                  sx={{
                    backgroundColor: "#F6F6F6",
                    borderRadius: 4,
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s",
                    "&:hover": {
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >

                  <CardMedia
                    component="img"
                    image={getFeaturedMedia(item)}
                    alt={'project'}
                  // sx={{
                  //   height: 200, // Adjust height as needed
                  //   objectFit: "cover",
                  // }}
                  />
                  <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: item?.content?.rendered }} />

                    {/* <Stack
                      sx={{
                        backgroundColor: "#FF600030",
                        width: "92px",
                        padding: 1,
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          background:
                            "linear-gradient(180deg, #F8B62D 0%, #EA5514 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        システム開発
                      </Typography>
                    </Stack>

                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#898989",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "25px",
                      }}
                    >
                      {item.description}
                    </Typography> */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {!isHide && (
            <Stack
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
                alignItems: "center",
                mt: 4,
              }}
              onClick={() => navigate(navi)}
            >
              <CustomButton text={subTitle} />
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default NumerousProjectsSection;
